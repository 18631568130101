import React, { Component } from "react";
import { connect } from "react-redux";
// import Dropzone from "dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUndo,
  faUserPlus,
  faEdit,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { 
  registerUser,
  fetchUser,
} from "../../services/index";
import MyToast from "../../utils/MyToast";
import { withRouter } from "react-router";
// import axios from "axios";
// import GLOBALS from '../Globals';

class UserRegister extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  initialState = {
    name: "",
    email: "",
    password: "",
    mobile: "",
    show: false,
    message: "",
    role: "",
    company: "",
    roles: [],
    companies: [],
    toastMsg: "",
    toastType: "",
    nikAo: "",
    flagAo: "none",
    loginAttempt: ""
  };
  
  componentDidMount() {
    const userId = +this.props.match.params.id;
    if (userId) {
      this.findUserById(userId);
    } else {
      this.setState({email: '', password: ''});
    }
    this.setState({roles: JSON.parse(localStorage.repoRole), companies: JSON.parse(localStorage.repoCompany)});
    
  }

  findUserById = (userId) => {
    this.props.fetchUser(userId);
    setTimeout(() => {
      let user = this.props.bookObject.user;
      if (user != null) {
        this.setState({
          id: user.id,
          name: user.name,
          email: user.email,
          password: user.password,
          mobile: user.mobile,
          // role: user.role.id,
          // company: user.company.code,
          nikAo: user.nik_ao,
          // loginAttempt: user.login_attempt
        });
      }
    }, 1000);
  };

  resetUser = () => {
    this.setState(() => this.initialState);
    // this.getAllRole();
    // this.getAllCompany();
  };

  userChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  selectCompany = (event) => {
    const comp = event.target.value;
    let varFlagAo = "none";
    if(comp === "BCI"){ // BCI
      varFlagAo = "block"
    }
    this.setState({
       flagAo: varFlagAo,
      [event.target.name]: comp.split("|")[0],
    });
  };
  
  userList = () => {
    return this.props.history.push("/user-list");
  };

  submitUser = (event) => {
    event.preventDefault();

    if(this.state.id === undefined || this.state.id === null){
      const pass = this.state.password;
      const re = new RegExp("^(?=.*)(?=.*[a-z])(?=.*[A-Z]).{8,32}$");
      const isOk = re.test(pass);

      if(!isOk) {
        return alert('at least minimum 8 characters\nat least one number\nat least one Uppercase\nat least one Lowercase');
      }
      
      alert('A password was submitted that was ' + pass.length + ' characters long.');
    }

    const user = {
      id: this.state.id,
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      mobile: this.state.mobile,
      role: this.state.role,
      company: this.state.company,
      nikAo: this.state.nikAo,
      loginAttempt: this.state.loginAttempt,
    };

    this.props.registerUser(user);
    setTimeout(() => {
    
      let userResult = this.props.bookObject.user;
      if ( typeof(userResult) !== 'undefined' ) {
        let msg = JSON.stringify(userResult);
        if(msg.indexOf("already registered") !== -1){
          this.setState({ show: true, toastType: "danger", toastMsg:"Email is already registered", method: "post" });
          setTimeout(() => this.setState({ show: false }), 2000);
        }else{
          let tmsg = null;
          if(this.state.id){
            tmsg="User Updated Successfully.";
          }else{
            tmsg="User Registered Successfully.";
          }
          this.setState({ show: true, toastType:"success", toastMsg: tmsg, method: "post" });
          setTimeout(() => this.setState({ show: false }), 2000);
          setTimeout(() => { 
            this.userList();
        }, 3000)
      }
      } else {
        this.setState({ show: false });
      }
    }, 2000);
    // this.setState(this.initialState);
  };
  

  render(){
    const { roles, companies, name, email, password, mobile, show, role, company, toastMsg, toastType, nikAo, loginAttempt } =
      this.state;

    return (
      <div>
        <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    {/* <h1 className="m-0">Welcome </h1> */}
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">                    
                      <li className="breadcrumb-item">Home</li>
                      <li className="breadcrumb-item active">User</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <MyToast
            show={show}
            message={toastMsg}
            type={toastType}
            />
            <div className="content">
              <div className="container-fluid">
                
                <div className="card card-light">
                  <div className="card-header">
                    <h3 className="card-title">
                      <FontAwesomeIcon icon={this.state.id ? faEdit : faUserPlus} />{" "}
                      {this.state.id ? "Update User" : "Register User"}
                    </h3>
                  </div>

                  <form 
                    onReset={this.resetUser}
                    onSubmit={this.submitUser}
                    id="formUserId"
                  >
                    <div className="card-body">
                      <div className="form-group row">
                        <label htmlFor="inputName" className="col-sm-1 col-form-label">Name</label>
                        <div className="col-sm-10">
                          <input type="text" onChange={this.userChange} className="form-control" name="name" value={name} placeholder="Enter Name" required/>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="inputEmail3" className="col-sm-1 col-form-label">Email</label>
                        <div className="col-sm-10">
                          <input type="email" onChange={this.userChange} className="form-control" name="email" value={email} placeholder="Email" required/>
                        </div>
                      </div>
                      {this.state.id ? "" : 
                      <div className="form-group row">
                        <label htmlFor="inputPassword3" className="col-sm-1 col-form-label">Password</label>
                        <div className="col-sm-10">
                          <input type="password" onChange={this.userChange} className="form-control" name="password" value={password} placeholder="Password" required/>
                        </div>
                      </div>
                      }
                      {/* <div className="form-group row">
                        <label htmlFor="inputPassword3" className="col-sm-1 col-form-label">Password Confirmation</label>
                        <div className="col-sm-10">
                          <input type="password" onChange={this.userChange} className="form-control" name="passwordConf" value={password} placeholder="Password Confirmation" required/>
                        </div>
                      </div> */}
                      <div className="form-group row">
                        <label htmlFor="inputPhone" className="col-sm-1 col-form-label">Phone</label>
                        <div className="col-sm-10">
                          <input type="text" onChange={this.userChange} className="form-control" name="mobile" value={mobile} placeholder="Enter Mobile Number" required/>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="selectCompany" className="col-sm-1 col-form-label">Company</label>
                        <div className="col-sm-3" >
                          <select className="form-control select2bs4" onChange={this.selectCompany} value={company} name="company" required>
                            <option defaultValue="Select Company" >Select Company</option>
                            {companies.map((companies) => (
                              <option value={companies.code}>{companies.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="selectRole" className="col-sm-1 col-form-label">Role</label>
                        <div className="col-sm-3" >
                          <select className="form-control select2bs4" onChange={this.userChange} value={role} name="role" required>
                            <option defaultValue="Select Role" >Select Role</option>
                            {roles.map((roles) => (
                              <option key={roles.id} value={roles.id}>{roles.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="inputNikAo" className="col-sm-1 col-form-label" style={{display: this.state.flagAo}}>NIK AO</label>
                        <div className="col-sm-10">
                          <input type="text" style={{display: this.state.flagAo}} onChange={this.userChange} className="form-control" name="nikAo" value={nikAo} placeholder="Enter NIK AO"/>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="inputloginAttempt" className="col-sm-1 col-form-label">Login Attempts</label>
                        <div className="col-sm-1">
                          <input type="text" onChange={this.userChange} className="form-control" name="loginAttempt" value={loginAttempt} required/>
                        </div>
                      </div>

                      {/* <div className="form-group row">
                      <label htmlFor="uploadKtp" className="col-sm-1 col-form-label">Upload KTP</label>
                          <div className="col-sm-3" >
                            <span class="btn btn-success fileinput-button">
                              <i class="glyphicon glyphicon-plus"></i>
                              <span>Add files...</span>
                            </span>
                          </div>
                      </div> */}


                    </div>

                    <div className="card-footer">
                      <button className="btn btn-success swalDefaultSuccess" type="submit" name="btnSubmit">
                          <FontAwesomeIcon icon={faSave} />{" "}
                          {this.state.id ? "Update" : "Register"}
                        </button>{" "}
                      <button size="md" className="btn btn-info" type="reset" name="btnReset">
                        <FontAwesomeIcon icon={faUndo} /> Reset
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bookObject: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerUser: (user) => dispatch(registerUser(user)),
    fetchUser: (userId) => dispatch(fetchUser(userId)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserRegister));
