import React, { Component } from "react";
import { connect } from "react-redux";
import "./../../assets/css/Style.css";
import {
  Card,
  Table,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faStepBackward,
  faFastBackward,
  faStepForward,
  faFastForward,
//   faSearch,
//   faTimes,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import GLOBALS from '../../utils/Globals';
import { withRouter } from "react-router";

class DocumentListByStatus extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      documents: [],
      search: "",
      currentPage: 1,
      documentPerPage: 10,
      sortDir: "asc",
      documentStatus: "",
    };
  }

  sortData = () => {
    setTimeout(() => {
      this.state.sortDir === "asc"
        ? this.setState({ sortDir: "desc" })
        : this.setState({ sortDir: "asc" });
      this.findAllDocument(this.state.currentPage);
    }, 500);
  };

  componentDidMount() {
    if (this.props.match.params.status) {
        this.findAllDocument(this.state.currentPage);
    }
  }

  findAllDocument(currentPage) {
    currentPage -= 1;
    axios
      .get(
        GLOBALS.BASE_URL + "/documents?pageNumber=" +
        currentPage +
        "&pageSize=" +
        this.state.documentPerPage +
        "&sortBy=id&sortDir=" +
        this.state.sortDir + 
        "&status=" + this.props.match.params.status,
         {
        headers: {
          "BCI-Key": GLOBALS.BCI_KEY
        }
      })
      .then((response) => response.data)
      .then((data) => {
        this.setState({
          
          documents: data.result.content,
          totalPages: data.result.totalPages,
          totalElements: data.result.totalElements,
          currentPage: data.result.number + 1,
        });
      })
      .catch((error) => {
        console.log(error);
        localStorage.removeItem("jwtToken");
        this.props.history.push("/");
      });
  }

  changePage = (event) => {
    let targetPage = parseInt(event.target.value);
    if (this.state.search) {
      this.searchData(targetPage);
    } else {
      this.findAllDocument(targetPage);
    }
    this.setState({
      [event.target.name]: targetPage,
    });
  };

  firstPage = () => {
    let firstPage = 1;
    if (this.state.currentPage > firstPage) {
      if (this.state.search) {
        this.searchData(firstPage);
      } else {
        this.findAllDocument(firstPage);
      }
    }
  };

  prevPage = () => {
    let prevPage = 1;
    if (this.state.currentPage > prevPage) {
      if (this.state.search) {
        this.searchData(this.state.currentPage - prevPage);
      } else {
        this.findAllDocument(this.state.currentPage - prevPage);
      }
    }
  };

  lastPage = () => {
    let condition = Math.ceil(
      this.state.totalElements / this.state.documentPerPage
    );
    if (this.state.currentPage < condition) {
      if (this.state.search) {
        this.searchData(condition);
      } else {
        this.findAllDocument(condition);
      }
    }
  };

  nextPage = () => {
    if (
      this.state.currentPage <
      Math.ceil(this.state.totalElements / this.state.documentPerPage)
    ) {
      if (this.state.search) {
        this.searchData(this.state.currentPage + 1);
      } else {
        this.findAllDocument(this.state.currentPage + 1);
      }
    }
  };

  searchChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  cancelSearch = () => {
    this.setState({ search: "" });
    this.findAllDocument(this.state.currentPage);
  };

  searchData = (currentPage) => {
    currentPage -= 1;
    axios
      .get(
        "http://localhost:8081/rest/books/search/" +
        this.state.search +
        "?page=" +
        currentPage +
        "&size=" +
        this.state.documentPerPage
      )
      .then((response) => response.data)
      .then((data) => {
        this.setState({
          books: data.result.content,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          currentPage: data.number + 1,
        });
      });
  };

  state = {
    isOpen: false
  };

  render() {
    const { documents, currentPage, totalPages } = this.state;

    if(typeof documents ==='undefined'){
      return(
        documents: 0
      )
    }

    return (
      <div>
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">                    
                    <li className="breadcrumb-item">Home</li>
                    <li className="breadcrumb-item active">Document List</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="content">
            <div className="container-fluid">
              <Card className={"border"}>
                <Card.Header>
                  <div style={{ float: "left" }}>
                    <FontAwesomeIcon icon={faList} /> Document List status {this.props.match.params.status}
                  </div>
                </Card.Header>
                <Card.Body className="card-body table-responsive p-0">
                  <Table bordered hover striped variant="light">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Ticket No</th>
                        <th>NIK</th>
                        <th>NPWP</th>
                        <th>SKEP</th>
                        <th>Status</th>
                        <th onClick={this.sortData}>
                          Doc Type{" "}
                          <div
                            className={
                              this.state.sortDir === "asc"
                                ? "arrow arrow-up"
                                : "arrow arrow-down"
                            }
                          >
                            {" "}
                          </div>
                        </th>
                        <th>Notes</th>
                        <th>Created By</th>
                        <th>Created Date</th>
                        <th>Modified By</th>
                        <th>Modified Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {documents.length === 0 ? (
                        <tr align="center">
                          <td colSpan="10">No Document Available.</td>
                        </tr>
                      ) : (
                        documents.map((document) => (
                          <tr key={document.id}>
                            <td>{document.name}</td>
                            <td>{document.ticketNo}</td>
                            <td>{document.nik}</td>
                            <td>{document.npwp}</td>
                            <td>{document.noSkep}</td>
                            <td>{document.status}</td>
                            <td>{document.type}</td>
                            <td>{document.notes}</td>
                            <td>{document.createdBy}</td>
                            <td>{document.createdDate}</td>
                            <td>{document.modifiedBy}</td>
                            <td>{document.modifiedDate}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </Card.Body>
                {documents.length > 0 ? (
                  <Card.Footer>
                    <div style={{ float: "left" }}>
                      Showing Page {currentPage} of {totalPages}
                    </div>
                    <div style={{ float: "right" }}>
                      <InputGroup size="sm">
                        <InputGroup.Prepend>
                          <Button
                            type="button"
                            variant="outline-info"
                            disabled={currentPage === 1 ? true : false}
                            onClick={this.firstPage}
                          >
                            <FontAwesomeIcon icon={faFastBackward} /> First
                          </Button>
                          <Button
                            type="button"
                            variant="outline-info"
                            disabled={currentPage === 1 ? true : false}
                            onClick={this.prevPage}
                          >
                            <FontAwesomeIcon icon={faStepBackward} /> Prev
                          </Button>
                        </InputGroup.Prepend>
                        <FormControl
                          className={"page-num bg-light"}
                          name="currentPage"
                          value={currentPage}
                          onChange={this.changePage}
                        />
                        <InputGroup.Append>
                          <Button
                            type="button"
                            variant="outline-info"
                            disabled={currentPage === totalPages ? true : false}
                            onClick={this.nextPage}
                          >
                            <FontAwesomeIcon icon={faStepForward} /> Next
                          </Button>
                          <Button
                            type="button"
                            variant="outline-info"
                            disabled={currentPage === totalPages ? true : false}
                            onClick={this.lastPage}
                          >
                            <FontAwesomeIcon icon={faFastForward} /> Last
                          </Button>
                        </InputGroup.Append>
                      </InputGroup>
                    </div>
                  </Card.Footer>
                ) : null}
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
}

const mapStateToProps = (state) => {
  return {
    bookObject: state.document,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DocumentListByStatus));
