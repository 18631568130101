import React, { Component } from "react";

import { connect } from "react-redux";
import { deleteUser } from "../../services/index";

import "./../../assets/css/Style.css";
import {
  Card,
  Table,
  ButtonGroup,
  Button,
  InputGroup,
  FormControl,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faStepBackward,
  faFastBackward,
  faStepForward,
  faFastForward,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import MyToast from "../../utils/MyToast";
import axios from "axios";
import GLOBALS from '../../utils/Globals';
import { withRouter } from "react-router";


class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: [],
      search: "",
      currentPage: 1,
      userPerPage: 5,
      sortDir: "asc",
      isOpen: false,
      ticketId: "",
    };
  }

  sortData = () => {
    setTimeout(() => {
      this.state.sortDir === "asc"
        ? this.setState({ sortDir: "desc" })
        : this.setState({ sortDir: "asc" });
      this.findAllUser(this.state.currentPage);
    }, 500);
  };

  componentDidMount() {
    this.findAllUser(this.state.currentPage);
  }

  findAllUser(currentPage) {
    let compId= "all";
    if(localStorage.role !== "ROLE_ADMIN"){
      compId = localStorage.company;
    }
    
    currentPage -= 1;
    axios
      .get(
        GLOBALS.BASE_URL + "/users?pageNumber=" +
          currentPage +
          "&pageSize=" +
          this.state.userPerPage +
          "&sortBy=id&sortDir=" +
          this.state.sortDir +
          "&companyId=" + 
          compId,{
            headers: {
             
        "BCI-Key": GLOBALS.BCI_KEY
            }}
      )
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        this.setState({
        
          user: data.content,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          currentPage: data.number + 1,
        });
      })
      .catch((error) => {
        console.log(error);
        localStorage.removeItem("jwtToken");
        this.props.history.push("/");
      });
  }


  changePage = (event) => {
    let targetPage = parseInt(event.target.value);
    if (this.state.search) {
      this.searchData(targetPage);
    } else {
      this.findAllUser(targetPage);
    }
    this.setState({
      [event.target.name]: targetPage,
    });
  };

  firstPage = () => {
    let firstPage = 1;
    if (this.state.currentPage > firstPage) {
      if (this.state.search) {
        this.searchData(firstPage);
      } else {
        this.findAllUser(firstPage);
      }
    }
  };

  prevPage = () => {
    let prevPage = 1;
    if (this.state.currentPage > prevPage) {
      if (this.state.search) {
        this.searchData(this.state.currentPage - prevPage);
      } else {
        this.findAllUser(this.state.currentPage - prevPage);
      }
    }
  };

  lastPage = () => {
    let condition = Math.ceil(
      this.state.totalElements / this.state.userPerPage
    );
    if (this.state.currentPage < condition) {
      if (this.state.search) {
        this.searchData(condition);
      } else {
        this.findAllUser(condition);
      }
    }
  };

  nextPage = () => {
    if (
      this.state.currentPage <
      Math.ceil(this.state.totalElements / this.state.userPerPage)
    ) {
      if (this.state.search) {
        this.searchData(this.state.currentPage + 1);
      } else {
        this.findAllUser(this.state.currentPage + 1);
      }
    }
  };

  searchChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  cancelSearch = () => {
    this.setState({ search: "" });
    this.findAllUser(this.state.currentPage);
  };

  searchData = (email) => {
    axios
      .get(
        GLOBALS.BASE_URL + "/users/find-email/" + email , {
        headers: {
          "BCI-Key": GLOBALS.BCI_KEY
        }
      }
      )
      .then((response) => response.data)
      .then((data) => {
        let dataResp = JSON.parse(data.result);
        this.setState({ user: dataResp.userList});
        localStorage.setItem("jwtToken", data.token);
      })
      .catch((error) => {
        console.log(error);
        localStorage.removeItem("jwtToken");
        this.props.history.push("/login");
      });
  };

  delUser = (bookId) => {
    this.props.deleteUser(bookId);
    setTimeout(() => {
      if (this.props.bookObject.user != null) {
        setTimeout(() => this.setState({ isOpen: false }), 1000);
        this.componentDidMount();
      } else {
        this.setState({ isOpen: false, });
      }
    }, 2000);
  };

  toggleModal = (itemId) => {
    this.setState({
      isOpen: !this.state.isOpen,
      ticketId: itemId
    });
  };

  render() {
    const { user, currentPage, totalPages, search } = this.state;
    // let userFilter = "0";
      
    // if(localStorage.role === "ROLE_FRONTING_ADMIN"){
    //   userFilter = user.map((user) => (
    //     user.company.id === localStorage.company ? 
    //   ));
    // }else{
    //   userFilter = user;
    // }

    return (
      <div>
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  {/* <h1 className="m-0">Welcome </h1> */}
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">                    
                    <li className="breadcrumb-item">Home</li>
                    <li className="breadcrumb-item active">User List</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <Modal show={this.state.isOpen} >
          <Modal.Body>
            <h3>Delete Data ?</h3>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={()=> this.delUser(this.state.ticketId)} >Delete</Button>
            <Button variant="secondary" onClick={()=> this.toggleModal()}>Cancel</Button>
          </Modal.Footer>
          </Modal>
          
          <div className="content">
            <div className="container-fluid">
              <div style={{ display: this.state.show ? "block" : "none" }}>
                <MyToast
                  show={this.state.show}
                  message={"Book Deleted Successfully."}
                  type={"danger"}
                />
              </div>

              <Card className={"border"}>
                <Card.Header>
                  <div style={{ float: "left" }}>
                    <FontAwesomeIcon icon={faList} /> User List
                  </div>
                  <div style={{ float: "right" }}>
                    <InputGroup size="sm">
                      <FormControl
                        placeholder="Search email"
                        name="search"
                        value={search}
                        className={"info-border bg-light text-white"}
                        onChange={this.searchChange}
                      />
                      <InputGroup.Append>
                        <Button
                          size="sm"
                          variant="outline-info"
                          type="button"
                          onClick={()=>this.searchData(search)}>
                        >
                          <FontAwesomeIcon icon={faSearch} />
                        </Button>
                        <Button
                          size="sm"
                          variant="outline-danger"
                          type="button"
                          onClick={this.cancelSearch}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Table bordered hover striped variant="light">
                    <thead>
                      <tr>
                        
                        <th onClick={this.sortData}>
                        Name{" "}
                          <div
                            className={
                              this.state.sortDir === "asc"
                                ? "arrow arrow-up"
                                : "arrow arrow-down"
                            }
                          >
                            {" "}
                          </div>
                        </th>
                        <th>Email</th>
                        <th>Mobile</th>                  
                        <th>Role</th>                  
                        <th>Company</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {user.length === 0 ? (
                        <tr align="center">
                          <td colSpan="7">No Document Available.</td>
                        </tr>
                      ) : (
                        user.map((user) => {
                            return(
                              <tr key={user.id}>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>{user.mobile}</td>
                                <td>{user.role.name}</td>                    
                                <td> {user.company.name}</td>
                                <td>
                                  <ButtonGroup>
                                    <Link to={"user-edit/" + user.id}>
                                      <Button 
                                        className="btn btn-outline-info"
                                        variant="outline-info" 
                                        size="sm">
                                        EDIT
                                      </Button>
                                    </Link>{' '}
                                    <span>&nbsp;&nbsp;</span>
                                    <Button
                                      size="sm"
                                      className="btn btn-outline-danger"
                                      variant="outline-danger"
                                      onClick={()=>this.toggleModal(user.id)}>
                                      DELETE
                                    </Button>
                                    <span>&nbsp;&nbsp;</span>
                                    <Link to={"/change-password/" + user.id}>
                                      <Button
                                        className="btn btn-outline-dark"
                                        variant="outline-dark"
                                        size="sm">
                                        CHANGE PASSWORD
                                      </Button>
                                    </Link>
                                  </ButtonGroup>
                                </td>
                              </tr>
                            );

                        })
                      )}
                    </tbody>
                  </Table>
                </Card.Body>
                {user.length > 0 ? (
                  <Card.Footer>
                    <div style={{ float: "left" }}>
                      Showing Page {currentPage} of {totalPages}
                    </div>
                    <div style={{ float: "right" }}>
                      <InputGroup size="sm">
                        <InputGroup.Prepend>
                          <Button
                            type="button"
                            variant="outline-info"
                            disabled={currentPage === 1 ? true : false}
                            onClick={this.firstPage}
                          >
                            <FontAwesomeIcon icon={faFastBackward} /> First
                          </Button>
                          <Button
                            type="button"
                            variant="outline-info"
                            disabled={currentPage === 1 ? true : false}
                            onClick={this.prevPage}
                          >
                            <FontAwesomeIcon icon={faStepBackward} /> Prev
                          </Button>
                        </InputGroup.Prepend>
                        <FormControl
                          className={"page-num bg-light"}
                          name="currentPage"
                          value={currentPage}
                          onChange={this.changePage}
                        />
                        <InputGroup.Append>
                          <Button
                            type="button"
                            variant="outline-info"
                            disabled={currentPage === totalPages ? true : false}
                            onClick={this.nextPage}
                          >
                            <FontAwesomeIcon icon={faStepForward} /> Next
                          </Button>
                          <Button
                            type="button"
                            variant="outline-info"
                            disabled={currentPage === totalPages ? true : false}
                            onClick={this.lastPage}
                          >
                            <FontAwesomeIcon icon={faFastForward} /> Last
                          </Button>
                        </InputGroup.Append>
                      </InputGroup>
                    </div>
                  </Card.Footer>
                ) : null}
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bookObject: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteUser: (bookId) => dispatch(deleteUser(bookId)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserList));
