export const SAVE_DOCUMENT_REQUEST = "SAVE_DOCUMENT_REQUEST";
export const FETCH_DOCUMENT_REQUEST = "FETCH_DOCUMENT_REQUEST";
export const UPDATE_DOCUMENT_REQUEST = "UPDATE_DOCUMENT_REQUEST";
export const DELETE_DOCUMENT_REQUEST = "DELETE_DOCUMENT_REQUEST";
export const VERIFY_DOCUMENT_REQUEST = "VERIFY_DOCUMENT_REQUEST";
export const REJECT_DOCUMENT_REQUEST = "REJECT_DOCUMENT_REQUEST";
export const GET_DOCUMENT_IMAGE_REQUEST = "GET_DOCUMENT_IMAGE_REQUEST";
export const DOCUMENT_SUCCESS = "DOCUMENT_SUCCESS";
export const DOCUMENT_FAILURE = "DOCUMENT_FAILURE";
export const IMAGE_SUCCESS = "IMAGE_SUCCESS";
export const IMAGE_FAILURE = "IMAGE_FAILURE";

