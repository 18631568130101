import * as BT from "./companyTypeTypes";

const initialState = {
  companyType: "",
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case BT.SAVE_COMPANYTYPE_REQUEST:
    case BT.FETCH_COMPANYTYPE_REQUEST:
    case BT.UPDATE_COMPANYTYPE_REQUEST:
    case BT.DELETE_COMPANYTYPE_REQUEST:   
    case BT.COMPANYTYPE_SUCCESS:
      return {
        companyType: action.payload,
        error: "",
      };
    case BT.COMPANYTYPE_FAILURE:
      return {
        companyType: "",
        error: action.payload,
      };  
      case BT.COMPANYTYPE_DELETE:
        return {
          companyType: action.payload,
          error: "",
        }; 
    default:
      return state;
  }
};

export default reducer;
