import React from "react";
import { NavLink } from "react-router-dom";

const NotFound = () => {
  localStorage.removeItem("jwtToken");
  return (
    <div>
      <div style={{ paddingTop: 200 }}>
        <div class="error-page">
          <h2 class="headline text-theme"> 404</h2>
          <div class="error-content">
            <h3>
              <i class="fas fa-exclamation-triangle text-theme"></i>
              Page not found.
            </h3>
            <p>
              The page you are looking for does not exist. Please back to{" "}
              <NavLink to="/login"> login page </NavLink>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
