import * as UT from "./userTypes";
import axios from "axios";
import GLOBALS from '../../utils/Globals';

const REGISTER_URL = GLOBALS.BASE_URL + "/user/register";

export const fetchUser = (userId) => {
  return (dispatch) => {
    dispatch({
      type: UT.FETCH_USER_REQUEST,
    });
    axios
      .get(GLOBALS.BASE_URL + "/users/" + userId,{headers: {
        "BCI-Key": GLOBALS.BCI_KEY
      }})
      .then((response) => {
        dispatch(userSuccess(response.data));
      })
      .catch((error) => {
        dispatch(userFailure(error));
      });
  };
};

export const registerUser = (userObject) => async (dispatch) => {
  dispatch(userRequest());
  try {
    const response = await axios.post(REGISTER_URL, userObject);
    dispatch(userSavedSuccess(response.data));
    localStorage.setItem("jwtToken", response.data.token);
    // return Promise.resolve(response.data);
  } catch (error) {
    dispatch(userFailure(error.message));
    // return Promise.reject(error);
  }
};

// export const findUserById = (userId) => async (dispatch) => {
//   dispatch(userRequest());
//   try {
//     const response = await axios.post(REGISTER_URL, userId);
//     dispatch(userSavedSuccess(response.data));
//     return Promise.resolve(response.data);
//   } catch (error) {
//     dispatch(userFailure(error.message));
//     return Promise.reject(error);
//   }
// };

export const deleteUser = (userId) => {
  return (dispatch) => {
    dispatch({
      type: UT.DELETE_USER_REQUEST,
    });
    axios
      .delete(GLOBALS.BASE_URL + "/user/delete/" + userId)
      .then((response) => {
        dispatch(userSuccess(response.data));
      })
      .catch((error) => {
        dispatch(userFailure(error));
      });
  };
};

const userRequest = () => {
  return {
    type: UT.FETCH_USER_REQUEST,
  };
};

const userSavedSuccess = (user) => {
  return {
    type: UT.USER_SAVED_SUCCESS,
    payload: user,
  };
};

const userSuccess = (user) => {
  return {
    type: UT.USER_SUCCESS,
    payload: user,
  };
};

const userFailure = (error) => {
  return {
    type: UT.USER_FAILURE,
    payload: error,
  };
};

