export const SAVE_USER_REQUEST = "SAVE_USER_REQUEST";
export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const USER_SAVED_SUCCESS = "USER_SAVED_SUCCESS";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAILURE = "USER_FAILURE";
export const USER_DELETE = "USER_DELETE";
export const FETCH_ROLES_REQUEST = "FETCH_ROLES_REQUEST";
export const ROLES_SUCCESS = "ROLES_SUCCESS";
export const ROLES_FAILURE = "ROLES_FAILURE";
export const FETCH_COMPANIES_REQUEST = "FETCH_COMPANIES_REQUEST";
export const COMPANIES_SUCCESS = "COMPANIES_SUCCESS";
export const COMPANIES_FAILURE = "COMPANIES_FAILURE";
