import * as UT from "./userTypes";

const initialState = {
  user: "",
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UT.FETCH_USER_REQUEST:
      return {
        user: action.payload,
        error: "",
      };
    case UT.USER_SUCCESS:
      return {
        user: action.payload,
        error: "",
      };
    case UT.USER_SAVED_SUCCESS:
      return {
        user: action.payload,
        error: "",
      };
    case UT.USER_FAILURE:
      return {
        user: "",
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
