import * as AT from "./authTypes";
import axios from "axios";
import GLOBALS from "../../../utils/Globals";
import * as UT from "../userTypes";

const AUTH_URL = GLOBALS.BASE_URL + "/v2/user/authenticate-web";
const RESET_PASS_URL = GLOBALS.BASE_URL + "/user/reset-password";

export const authenticateUser = (email, password) => async (dispatch) => {
  dispatch(loginRequest());
  delete axios.defaults.headers.common["Authorization"];
  try {
    const response = await axios.post(
      AUTH_URL,
      {
        email: email,
        password: password,
      },
      {
        headers: {
          // 'application/json' is the modern content-type for JSON, but some
          // older servers may use 'text/json'.
          // See: http://bit.ly/text-json

          "BCI-Key": GLOBALS.BCI_KEY,
        },
      }
    );

    // localStorage.setItem("respCode", response.data.responseCode);
    localStorage.setItem("respCode", response.data.errorCode);

    // response.data = JSON.parse(response.data.result);
    if (
      response.data.role === 'ROLE_FRONTING_MAKER'
      // JSON.stringify(response.data.role).indexOf(
      //   "ROLE_FRONTING_MAKER"
      // ) !== -1
    ) {
      dispatch(failure());
      return Promise.reject("Not Authorized");
    } else {
      localStorage.setItem("jwtToken", response.data.Token);
      localStorage.setItem("authority", response.data.role);
      localStorage.setItem("repoRole", response.data.role);
      // localStorage.setItem(
      //   "repoCompany",
      //   JSON.stringify(response.data.repoCompany)
      // );
      localStorage.setItem("company", response.data.companyId);
      localStorage.setItem("userId", response.data.id);
      localStorage.setItem("email", response.data.name);
      // localStorage.setItem("password", password);
      // let bookGenres = response.data.authorities;
      // if (bookGenres) {
      //   bookGenres.map((genre) =>
      //     localStorage.setItem("role", genre.authority)
      //   );
      // }
      localStorage.setItem("role", "ROLE_" + response.data.role);

      dispatch(success({ username: response.data.name, isLoggedIn: true }));
      return Promise.resolve(response.data);
    }
  } catch (error) {
    dispatch(failure());
    return Promise.reject(error);
  }
};

// @DeleteMapping("delete/{id}")
export const findByEmail = (user) => async (dispatch) => {
  try {
    const response = await axios.post(RESET_PASS_URL, user, {
      headers: {
        "BCI-Key": GLOBALS.BCI_KEY,
      },
    });
    dispatch(success({ username: response.data }));
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch(userFailure());
    return Promise.reject(error);
  }
};

export const logoutUser = () => {
  return (dispatch) => {
    dispatch(logoutRequest());
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("roleRepo");
    localStorage.removeItem("roleCompany");
    localStorage.removeItem("userId");
    localStorage.removeItem("email");
    localStorage.clear();
    dispatch(success({ username: "", isLoggedIn: false }));
  };
};

const loginRequest = () => {
  return {
    type: AT.LOGIN_REQUEST,
  };
};

const logoutRequest = () => {
  return {
    type: AT.LOGOUT_REQUEST,
  };
};

const success = (isLoggedIn) => {
  return {
    type: AT.SUCCESS,
    payload: isLoggedIn,
  };
};

const failure = () => {
  return {
    type: AT.FAILURE,
    payload: false,
  };
};

// const userSuccess = (user) => {
//   return {
//     type: UT.USER_SUCCESS,
//     payload: user,
//   };
// };

const userFailure = (error) => {
  return {
    type: UT.USER_FAILURE,
    payload: error,
  };
};
