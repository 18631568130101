import * as BT from "./roleTypes";

const initialState = {
  roles: "",
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case BT.SAVE_ROLE_REQUEST:
    case BT.FETCH_ROLE_REQUEST:
    case BT.UPDATE_ROLE_REQUEST:
    case BT.DELETE_ROLE_REQUEST:
    case BT.ROLE_SUCCESS:
      return {
        roles: action.payload,
        error: "",
      };
    case BT.ROLE_FAILURE:
      return {
        roles: "",
        error: action.payload,
      };
    case BT.ROLE_DELETE:
      return {
        roles: action.payload,
        error: "",
      };
    default:
      return state;
  }
};

export default reducer;
