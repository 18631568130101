import React, { Component } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo, faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import { fetchUser } from "../../services/index";
import MyToast from "../../utils/MyToast";
import { withRouter } from "react-router";
import axios from "axios";
import GLOBALS from "../../utils/Globals";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  initialState = {
    name: "",
    email: localStorage.email.replace(/['"]+/g, ""),
    oldPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
    show: false,
    message: "",
    toastMsg: "",
    toastType: "",
    userRole: localStorage.role,
    flagAdmin: localStorage.role === "ROLE_ADMIN" ? "none" : "block",
  };

  componentDidMount() {
    if (this.props.match.params.id !== "") {
      const userId = +this.props.match.params.id;
      this.findUserById(userId);
    } else {
      const userId = localStorage.userId;
      this.findUserById(userId);
    }
  }

  findUserById = (userId) => {
    this.props.fetchUser(userId);
    setTimeout(() => {
      let user = this.props.bookObject.user;
      if (user != null) {
        this.setState({
          id: user.id,
          email: user.email,
        });
      }
    }, 1000);
  };

  resetUser = () => {
    this.setState(() => this.initialState);
  };

  userChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  submitUser = (event) => {
    event.preventDefault();

    const pass = this.state.newPassword;
    const re = new RegExp("^(?=.*)(?=.*[a-z])(?=.*[A-Z]).{8,32}$");
    const isOk = re.test(pass);

    if (!isOk) {
      return alert(
        "at least minimum 8 characters\nat least one number\nat least one Uppercase\nat least one Lowercase"
      );
    }

    // alert('A password was submitted that was ' + pass.length + ' characters long.');

    let varOldPass = this.state.oldPassword;
    // if (this.state.userRole === "ROLE_ADMIN") {
    //   varOldPass = "resetAdmin";
    // }

    const user = {
      id: this.state.id,
      email: this.state.email,
      oldPassword: varOldPass,
      newPassword: this.state.newPassword,
    };

    if (user.newPassword !== this.state.newPasswordConfirm) {
      this.setState({
        show: true,
        toastType: "danger",
        toastMsg: "The new password and confirmation password do not match!",
      });
      setTimeout(() => this.setState({ show: false }), 2000);
    } else {
      axios
        .post(GLOBALS.BASE_URL + "/user/change-password", user, {
          headers: { "BCI-Key": GLOBALS.BCI_KEY },
        })
        .then((response) => response.data)
        .then((data) => {
          let userAfter = data.responseDesc;
          if (userAfter === "not success") {
            this.setState({
              show: true,
              toastType: "danger",
              toastMsg: "The old password you have entered is incorrect",
              method: "post",
            });
            setTimeout(() => this.setState({ show: false }), 2000);
            this.componentDidMount();
          } else {
            this.setState({
              show: true,
              toastType: "success",
              toastMsg: "Your password has been changed",
              method: "post",
            });
            setTimeout(() => this.setState({ show: false }), 2000);
            localStorage.removeItem("respCode");
            setTimeout(() => {
              return this.props.history.push("/home");
            }, 3000);
          }
        })
        .catch((error) => {
          console.log(error);
          localStorage.removeItem("jwtToken");
          this.props.history.push("/");
        });
    }
  };

  render() {
    const {
      email,
      oldPassword,
      newPassword,
      newPasswordConfirm,
      show,
      toastMsg,
      toastType,
    } = this.state;

    return (
      <div>
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6"></div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">Home</li>
                    <li className="breadcrumb-item active">User</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <MyToast show={show} message={toastMsg} type={toastType} />
          <div className="content">
            <div className="container-fluid">
              <div className="card card-light">
                <div className="card-header">
                  <h3 className="card-title">
                    <FontAwesomeIcon icon={faEdit} /> {"Change Password"}
                  </h3>
                </div>

                <form
                  onReset={this.resetUser}
                  onSubmit={this.submitUser}
                  id="formUserId"
                >
                  <div className="card-body">
                    <div className="form-group row">
                      <label
                        htmlFor="email"
                        className="col-sm-2 col-form-label"
                      >
                        Email
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="email"
                          onChange={this.userChange}
                          className="form-control"
                          name="email"
                          value={email}
                          placeholder="Email"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="oldPassword"
                        className="col-sm-2 col-form-label"
                        // style={{ display: this.state.flagAdmin }}
                      >
                        Old Password
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="password"
                          onChange={this.userChange}
                          className="form-control"
                          name="oldPassword"
                          value={oldPassword}
                          placeholder="Enter Old Password"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="newPassword"
                        className="col-sm-2 col-form-label"
                      >
                        New Password
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="password"
                          onChange={this.userChange}
                          className="form-control"
                          name="newPassword"
                          value={newPassword}
                          placeholder="Enter New Password"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="newPasswordConfirm"
                        className="col-sm-2 col-form-label"
                      >
                        Confirm New Password
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="password"
                          onChange={this.userChange}
                          className="form-control"
                          name="newPasswordConfirm"
                          value={newPasswordConfirm}
                          placeholder="Re-enter new Password"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <button
                      className="btn btn-success swalDefaultSuccess"
                      type="submit"
                      name="btnSubmit"
                    >
                      <FontAwesomeIcon icon={faSave} /> {"Change Password"}
                    </button>{" "}
                    <button
                      size="md"
                      className="btn btn-info"
                      type="reset"
                      name="btnReset"
                    >
                      <FontAwesomeIcon icon={faUndo} /> Reset
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bookObject: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: (userId) => dispatch(fetchUser(userId)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
);
