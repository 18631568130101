import React, { useState, useEffect } from "react";

const Footer = () => {
  const [fullYear, setFullYear] = useState();

  useEffect(() => {
    setFullYear(new Date().getFullYear());
  }, [fullYear]);

  return (
    <div>
      <footer className="main-footer">
      <div className="float-right d-none d-sm-block">
        <b>Version</b> 1.0.0
      </div>
      <strong> BCI-Dapen Document Management. {new Date().getFullYear()}</strong> .
    </footer>
  </div>
  );
};
    
export default Footer;
