import React, { useState } from "react";

const Documents = () => {
    const [docs, setDocs] = useState([])
    let c = ['satu', 'dua', 'tiga']
    setDocs(c)

    return (
        <div>
            <ul>
                {docs.map((c) => (
                    <li key={c.id}>{c}</li>
                ))}
            </ul>
        </div>
    )
}

export default Documents;