import React from "react";
import "./App.css";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { lazy, Suspense } from "react";

import NotFound from "./components/Layout/NotFound";

import Home from "./components/Layout/Home";
import CompanyList from "./components/Company/CompanyList";
import Company from "./components/Company/CompanyRegister";
import CompanyTypeList from "./components/CompanyType/CompanyTypeList";
import CompanyType from "./components/CompanyType/CompanyTypeRegister";
import DocumentList from "./components/Document/DocumentList";
import DocumentVerify from "./components/Document/DocumentVerify";
import DocumentByStatus from "./components/Document/DocumentListByStatus";
import DocumentAssign from "./components/Document/DocumentAssign";
// import DocumentAsuransi from "./components/Document/DocumentAsuransiVideo";

import UserList from "./components/User/UserList";
import UserRegister from "./components/User/UserRegister";
import ChangePassword from "./components/User/ChangePassword";

import RoleList from "./components/Role/RoleList";
import RoleRegister from "./components/Role/RoleRegister";

import ReportTicket from "./components/Report/ReportTicket";

import PublicRoute from "./router/PublicRoutes";
import PrivateRoute from "./router/PrivateRoute";
import Documents from "./components/Channeling/documents";

const LoginPage = lazy(() => import('./components/User/Login'));
const ResetPassword = lazy(() => import('./components/User/ResetPassword'));

export default function App() {
  const auth = useSelector((state) => state.auth);
  const [authCheck, setAuthCheck] = React.useState(false);

  React.useEffect(() => {
    const checkMoreData = auth.isLoggedIn ? true : false
    setAuthCheck(checkMoreData);
  }, [auth]);

  return (
    
      <Router>
        <Suspense fallback={<h1>Loading.. </h1> }>
          <div className="wrapper">
            <Switch>
              <Route exact path='/' render={() => authCheck
                ? <Redirect to={`/home`} />
                : <Redirect to={`/login`} />}
              />
              <PublicRoute path="/login" isAuthenticated={authCheck}>
                <LoginPage />
              </PublicRoute>
              <PublicRoute path="/reset-password" isAuthenticated={authCheck}>
                <ResetPassword />
              </PublicRoute>
              
              <PrivateRoute path="/home"> <Home /> </PrivateRoute>
              <PrivateRoute path="/document-list" > <DocumentList /> </PrivateRoute>
              <PrivateRoute path="/channeling-list" > <Documents /> </PrivateRoute>
              <PrivateRoute path="/document-verify/:id"> <DocumentVerify /> </PrivateRoute>
              <PrivateRoute path="/channeling-verify/:id"> <DocumentVerify /> </PrivateRoute>
              <PrivateRoute path="/document-status/:status"> <DocumentByStatus /> </PrivateRoute>
              <PrivateRoute path="/document-assign"> <DocumentAssign /> </PrivateRoute>
              {/* <PrivateRoute path="/document-asuransi"> <DocumentAsuransi /> </PrivateRoute> */}

              <PrivateRoute path="/user-list"> <UserList /> </PrivateRoute>
              <PrivateRoute path="/users" > <UserRegister /> </PrivateRoute>
              <PrivateRoute path="/user-edit/:id" > <UserRegister /> </PrivateRoute>
              <PrivateRoute path="/change-password/:id" > <ChangePassword /> </PrivateRoute>

              <PrivateRoute path="/company-list" > <CompanyList /> </PrivateRoute>
              <PrivateRoute path="/company" > <Company /> </PrivateRoute>
              <PrivateRoute path="/company-edit/:id" > <Company /> </PrivateRoute>

              <PrivateRoute path="/companyType-list" > <CompanyTypeList /> </PrivateRoute>
              <PrivateRoute path="/companyType" > <CompanyType /> </PrivateRoute>
              <PrivateRoute path="/companyType-edit/:id" > <CompanyType /> </PrivateRoute>

              <PrivateRoute path="/role-list" > <RoleList /> </PrivateRoute>
              <PrivateRoute path="/roles" > <RoleRegister /> </PrivateRoute>
              <PrivateRoute path="/role-edit/:id" > <RoleRegister /> </PrivateRoute>

              <PrivateRoute path="/report" > <ReportTicket /> </PrivateRoute>
              <PrivateRoute path="/report-ticket"> <ReportTicket /> </PrivateRoute>
              <PublicRoute path="/*" isAuthenticated={authCheck}>
                <NotFound />
              </PublicRoute>

            </Switch>
          </div>
        </Suspense>
      </Router>
  )
}

