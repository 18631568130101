import React, { Component } from "react";
import { connect } from "react-redux";
import Select from 'react-select';
import axios from "axios";
import GLOBALS from '../../utils/Globals';
import {
  saveCompany,
  fetchCompany,
} from "../../services/index";
import { Card, Form, Button, Col, Row } from "react-bootstrap";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faPlusSquare,
  faUndo,
  faList,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import "../../App.css";
import MyToast from "../../utils/MyToast";
import { withRouter } from "react-router";


class Company extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  initialState = {
    code: "",
    name: "",
    npwp: "",
    verify: false,
    pic: "",
    address: "",
    companyTypeId:"",
    companiesTypeSelected:[{value:'',label:''}],
    companiesType: [],
    show: false,
    errorField: [],
    errorMsg: "",
  };

  componentDidMount() {
    const companyId = +this.props.match.params.id;
    this.findAllCompanyType();
    if (companyId) {
      this.findCompanyById(companyId);
     
    }
  }

  findAllCompanyType = () => {
    setTimeout(() => {
      axios
      .get(
        GLOBALS.BASE_URL + "/companiestype/all",{
            headers: {
              "BCI-Key": GLOBALS.BCI_KEY
            }}
      )
      .then((response) => response.data)
      .then((data) => {
        this.setState({
          companiesType: data.map(x=> ({value:x.id, label:x.companyTypeName}))
        });
        
      })
      .catch((error) => {
        console.log(error);
        localStorage.removeItem("jwtToken");
        this.props.history.push("/");
      });
    }, 2000);
   
  }

  findCompanyById = (companyId) => {
    this.props.fetchCompany(companyId);
    setTimeout(() => {
      let company = this.props.bookObject.company;
      if (company != null) {
        this.setState({
          id: company.id,
          code: company.code,
          name: company.name,
          npwp: company.npwp,
          verify: company.verify,
          pic: company.pic,
          address: company.address,
          companyTypeId : company.companyType.id,
          companiesTypeSelected: [{value: company.companyType.id, label:company.companyType.companyTypeName}]
        });

        //this.companyTypeChange(this.state.companiesTypeSelected);
      }
    }, 1000);
  };

  resetCompany = () => {
    this.setState(() => this.initialState);
  };

  submitCompany = (event) => {
    event.preventDefault();

    var errors = [];
    var errorMsg = "";

    if (this.state.npwp.length > 16) {
      errorMsg = "NPWP cannot greater than 16";
      errors.push("npwp");
    }

    this.setState({
      errorMsg: errorMsg,
      errorField: errors
    });

    if (errors.length > 0) {
      return false;
    }

    const company = {
      id: this.state.id,
      code: this.state.code,
      name: this.state.name,
      npwp: this.state.npwp,
      verify: this.state.verify,
      pic: this.state.pic,
      address: this.state.address,
      companyTypeId: this.state.companyTypeId
    };

    this.props.saveCompany(company);
    setTimeout(() => {
      if (this.props.bookObject.company != null) {
        this.setState({ show: true, method: "post" });
        setTimeout(() => this.setState({ show: false }), 2000);
        setTimeout(() => { 
          this.companyList();
      }, 3000)
      } else {
        this.setState({ show: false });
      }
    }, 2000);
    this.setState(this.initialState);
  };

  companyChange = (event) => {
    var key = event.target.name;
    var value = event.target.value;
    var obj = {};
    obj[key] = value;
    this.setState(obj);
    // this.setState({
    //   [event.target.name]: event.target.value,
    // });
    if(this.state.errorField.indexOf(key) !== -1){
      this.setState({errorField: []});
    }
  };

  companyTypeChange = (e) => {
    this.setState({
      label: e.label,
      value: e.value
    });
    this.setState({companyTypeId : e.value,
      companiesTypeSelected: [{value: e.value, label:e.label}]
    });    

  };
  
  companyList = () => {
    return this.props.history.push("/company-list");
  };

  hasError(key) {
    return this.state.errorField.indexOf(key) !== -1;
  }
  render() {
    

    
    const { code, name, address, pic, npwp, companiesType,companiesTypeSelected } =
      this.state;

    return (
      <div>
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  {/* <h1 className="m-0">Welcome </h1> */}
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">                    
                    <li className="breadcrumb-item">Home</li>
                    <li className="breadcrumb-item active">Company</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <MyToast
            show={this.state.show}
            message={
              this.state.id
                ? "Company Updated Successfully."
                : "Company Saved Successfully."
            }
            type={"success"}
          />

          <div className="content">
            <div className="container-fluid">
              <Card className={"border"}>
                <Card.Header>
                  <FontAwesomeIcon icon={this.state.id ? faEdit : faPlusSquare} />{" "}
                  {this.state.id ? "Update Company" : "Add New Company"}
                </Card.Header>
                <Form
                  onReset={this.resetCompany}
                  onSubmit={this.submitCompany}
                  id="bookFormId"
                >
                  <Card.Body>
                    <Form.Group as={Row} className="mb-3" controlId="formGridName">
                      <Form.Label column sm="1">Code</Form.Label>
                      <Col sm="11">
                        <Form.Control 
                          required
                          autoComplete="off"
                          maxLength={3}
                          minLength={3}
                          type="test"
                          name="code"
                          value={code}
                          onChange={this.companyChange}
                          placeholder="Enter Company Code"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="formGridName">
                      <Form.Label column sm="1">Name</Form.Label>
                      <Col sm="11">
                        <Form.Control
                          required
                          autoComplete="off"
                          type="test"
                          name="name"
                          value={name}
                          variant="outlined"
                          onChange={this.companyChange}
                          placeholder="Enter Company Name"
                          style={name === '' ? { color: 'rgba(0, 0, 0, 0.5)' } : {}}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="formGridName">
                      <Form.Label column sm="1">NPWP</Form.Label>
                      <Col sm="11">
                        <Form.Control
                          className={
                            this.hasError("npwp")
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          required
                          autoComplete="off"
                          type="number"
                          name="npwp"
                          value={npwp}
                          onChange={this.companyChange}
                          placeholder="Enter Company NPWP"
                        />
                        <Form.Control.Feedback type={this.hasError("npwp") ? 'invalid' : 'valid'} >
                          {this.state.errorMsg}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="formGridPic">
                      <Form.Label column sm="1">PIC</Form.Label>
                      <Col sm="11">
                        <Form.Control
                          required
                          autoComplete="off"
                          type="test"
                          name="pic"
                          value={pic}
                          onChange={this.companyChange}
                          placeholder="Enter PIC Name"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="formGridName">
                      <Form.Label column sm="1">Address</Form.Label>
                      <Col sm="11">
                        <Form.Control
                          required
                          autoComplete="off"
                          type="test"
                          name="address"
                          value={address}
                          onChange={this.companyChange}
                          placeholder="Enter Company Address"
                        />
                      </Col>
                    </Form.Group>

                    {/* <div className="form-group row">
                        <label htmlFor="selectCompany" className="col-sm-1 col-form-label">Company Type</label>
                        <div className="col-sm-3" >
                          <select className="form-control select2bs4" onChange={this.selectcompanyTypeId} value={companyTypeId} name="companyTypeId" required>
                            <option defaultValue="Select Company Type" >Select Company Type</option>
                            {companiesType.map((companiesType) => (
                              <option value={companiesType.id}>{companiesType.companyTypeName}</option>
                            ))}
                          </select>
                        </div>
                      </div> */}
                      
                      <Form.Group as={Row} className="mb-3" controlId="formGridName">
                        <Form.Label column sm="1">Company Type</Form.Label>
                        <Col sm="11">
                        <Select
                            //defaultValue={companiesTypeSelected}
                            name="companyTypeId"
                            //value={companyTypeId}
                            //onMenuOpen={this.findAllCompanyType()}
                            //Value={{ label: companiesTypeSelected.label, value: companiesTypeSelected.value }}
                            value={companiesTypeSelected}
                            options={companiesType}
                            onChange={this.companyTypeChange}
                            className="basic-single"
                            classNamePrefix="select"
                            />
                        </Col>
                      </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="formGridName">
                      <Form.Label className="col-verify" column sm="-1">Verificator</Form.Label>
                      <Col md="9">
                        <BootstrapSwitchButton
                          checked={false}
                          width={100}
                          onlabel='Activate'
                          offlabel='Inactive'
                          onChange={(checked: boolean) => {
                              this.setState({ verify: true })
                          }}
                        />
                      </Col>
                    </Form.Group>

                    </Card.Body>
                    <Card.Footer style={{ textAlign: "right" }}>
                      <Button size="sm" variant="success" type="submit">
                        <FontAwesomeIcon icon={faSave} />{" "}
                        {this.state.id ? "Update" : "Save"}
                      </Button>{" "}
                      <Button size="sm" variant="info" type="reset">
                        <FontAwesomeIcon icon={faUndo} /> Reset
                      </Button>{" "}
                      <Button
                        size="sm"
                        variant="info"
                        type="button"
                        onClick={() => this.companyList()}
                      >
                        <FontAwesomeIcon icon={faList} /> Company List
                      </Button>
                    </Card.Footer>
                  </Form>
                </Card>      
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bookObject: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveCompany: (company) => dispatch(saveCompany(company)),
    fetchCompany: (companyId) => dispatch(fetchCompany(companyId)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Company));
