import React, { Component } from "react";

import { connect } from "react-redux";
import { deleteRole } from "../../services/index";

import "./../../assets/css/Style.css";
import {
  Card,
  Table,
  ButtonGroup,
  Button,
  InputGroup,
  FormControl,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faStepBackward,
  faFastBackward,
  faStepForward,
  faFastForward,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import axios from "axios";
import GLOBALS from '../../utils/Globals';
import { withRouter } from "react-router";


class RoleList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
      search: "",
      currentPage: 1,
      booksPerPage: 5,
      sortDir: "asc",
      // show: false,F
      isOpen: false,
      ticketId: "",
    };
  }

  sortData = () => {
    setTimeout(() => {
      this.state.sortDir === "asc"
        ? this.setState({ sortDir: "desc" })
        : this.setState({ sortDir: "asc" });
      this.findAllRoles(this.state.currentPage);
    }, 500);
  };

  componentDidMount() {
    this.findAllRoles(this.state.currentPage);
  }

  findAllRoles(currentPage) {
    currentPage -= 1;
    axios
      .get(
        GLOBALS.BASE_URL + "/roles?pageNumber=" +
          currentPage +
          "&pageSize=" +
          this.state.booksPerPage +
          "&sortBy=id&sortDir=" +
          this.state.sortDir,{
            headers: {
             
        "BCI-Key": GLOBALS.BCI_KEY
            }}
      )
      .then((response) => response.data)
      .then((data) => {
        this.setState({
          roles: data.content,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          currentPage: data.number + 1,
        });
      })
      .catch((error) => {
        console.log(error);
        localStorage.removeItem("jwtToken");
        this.props.history.push("/");
      });
  }

  deleteRoles = (roleId) => {
    this.props.deleteRole(roleId);
    setTimeout(() => {
      if (this.props.bookObject.roles != null) {
        setTimeout(() => this.setState({ isOpen: false }), 1000);
        this.componentDidMount();
      } else {
        this.setState({ isOpen: false, });
      }
    }, 2000);
  };


  changePage = (event) => {
    let targetPage = parseInt(event.target.value);
    if (this.state.search) {
      this.searchData(targetPage);
    } else {
      this.findAllRoles(targetPage);
    }
    this.setState({
      [event.target.name]: targetPage,
    });
  };

  firstPage = () => {
    let firstPage = 1;
    if (this.state.currentPage > firstPage) {
      if (this.state.search) {
        this.searchData(firstPage);
      } else {
        this.findAllRoles(firstPage);
      }
    }
  };

  prevPage = () => {
    let prevPage = 1;
    if (this.state.currentPage > prevPage) {
      if (this.state.search) {
        this.searchData(this.state.currentPage - prevPage);
      } else {
        this.findAllRoles(this.state.currentPage - prevPage);
      }
    }
  };

  lastPage = () => {
    let condition = Math.ceil(
      this.state.totalElements / this.state.booksPerPage
    );
    if (this.state.currentPage < condition) {
      if (this.state.search) {
        this.searchData(condition);
      } else {
        this.findAllRoles(condition);
      }
    }
  };

  nextPage = () => {
    if (
      this.state.currentPage <
      Math.ceil(this.state.totalElements / this.state.booksPerPage)
    ) {
      if (this.state.search) {
        this.searchData(this.state.currentPage + 1);
      } else {
        this.findAllRoles(this.state.currentPage + 1);
      }
    }
  };

  searchChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  cancelSearch = () => {
    this.setState({ search: "" });
    this.findAllRoles(this.state.currentPage);
  };

  searchData = (currentPage) => {
    currentPage -= 1;
    axios
      .get(
        GLOBALS.BASE_URL + "/rest/books/search/" +
          this.state.search +
          "?page=" +
          currentPage +
          "&size=" +
          this.state.booksPerPage
      )
      .then((response) => response.data)
      .then((data) => {
        this.setState({
          books: data.content,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          currentPage: data.number + 1,
        });
      });
  };

  toggleModal = (itemId) => {
    this.setState({
      isOpen: !this.state.isOpen,
      ticketId: itemId
    });
  };


  render() {
    const { roles, currentPage, totalPages, search } = this.state;

    return (
      <div>
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  {/* <h1 className="m-0">Welcome </h1> */}
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">                    
                    <li className="breadcrumb-item">Home</li>
                    <li className="breadcrumb-item active">Role List</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <Modal show={this.state.isOpen} >
          <Modal.Body>
            <h3>Delete Data ?</h3>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={()=> this.deleteRoles(this.state.ticketId)} >Delete</Button>
            <Button variant="secondary" onClick={()=> this.toggleModal()}>Cancel</Button>
          </Modal.Footer>
          </Modal>

          <div className="content">
            <div className="container-fluid">

              <Card className={"border"}>
                <Card.Header>
                  <div style={{ float: "left" }}>
                    <FontAwesomeIcon icon={faList} /> Role List
                  </div>
                  <div style={{ float: "right" }}>
                    <InputGroup size="sm">
                      <FormControl
                        placeholder="Search"
                        name="search"
                        value={search}
                        className={"info-border bg-light text-white"}
                        onChange={this.searchChange}
                      />
                      <InputGroup.Append>
                        <Button
                          size="sm"
                          variant="outline-info"
                          type="button"
                          onClick={this.searchData}
                        >
                          <FontAwesomeIcon icon={faSearch} />
                        </Button>
                        <Button
                          size="sm"
                          variant="outline-danger"
                          type="button"
                          onClick={this.cancelSearch}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>
                </Card.Header>
                <Card.Body className="card-body table-responsive p-0">
                  <Table bordered hover striped variant="light">
                    <thead>
                      <tr>
                        <th onClick={this.sortData}>
                        ID{" "}
                          <div
                            className={
                              this.state.sortDir === "asc"
                                ? "arrow arrow-up"
                                : "arrow arrow-down"
                            }
                          >
                            {" "}
                          </div>
                        </th>
                        <th>Name</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {roles.length === 0 ? (
                        <tr align="center">
                          <td colSpan="7">No Role Available.</td>
                        </tr>
                      ) : (
                        roles.map((role) => (
                          <tr key={role.id}>
                            <td>{role.id}</td>
                            <td>{role.name}</td>
                            <td>
                                <ButtonGroup>
                                    <Link to={"role-edit/" + role.id}>
                                    <Button 
                                        className="btn btn-outline-info"
                                        variant="outline-info" 
                                        size="sm">
                                        EDIT
                                    </Button>
                                    </Link>{' '}
                                    <span>&nbsp;&nbsp;</span>
                                    <Button
                                    size="sm"
                                    className="btn btn-outline-danger"
                                    variant="outline-danger"
                                    onClick={()=>this.toggleModal(role.id)}>
                                    DELETE
                                    </Button>
                                </ButtonGroup>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </Card.Body>
                {roles.length > 0 ? (
                  <Card.Footer>
                    <div style={{ float: "left" }}>
                      Showing Page {currentPage} of {totalPages}
                    </div>
                    <div style={{ float: "right" }}>
                      <InputGroup size="sm">
                        <InputGroup.Prepend>
                          <Button
                            type="button"
                            variant="outline-info"
                            disabled={currentPage === 1 ? true : false}
                            onClick={this.firstPage}
                          >
                            <FontAwesomeIcon icon={faFastBackward} /> First
                          </Button>
                          <Button
                            type="button"
                            variant="outline-info"
                            disabled={currentPage === 1 ? true : false}
                            onClick={this.prevPage}
                          >
                            <FontAwesomeIcon icon={faStepBackward} /> Prev
                          </Button>
                        </InputGroup.Prepend>
                        <FormControl
                          className={"page-num bg-light"}
                          name="currentPage"
                          value={currentPage}
                          onChange={this.changePage}
                        />
                        <InputGroup.Append>
                          <Button
                            type="button"
                            variant="outline-info"
                            disabled={currentPage === totalPages ? true : false}
                            onClick={this.nextPage}
                          >
                            <FontAwesomeIcon icon={faStepForward} /> Next
                          </Button>
                          <Button
                            type="button"
                            variant="outline-info"
                            disabled={currentPage === totalPages ? true : false}
                            onClick={this.lastPage}
                          >
                            <FontAwesomeIcon icon={faFastForward} /> Last
                          </Button>
                        </InputGroup.Append>
                      </InputGroup>
                    </div>
                  </Card.Footer>
                ) : null}
              </Card>
            </div>

            {/* <div className="card card-light">
                    <div className="card-header">
                        <h3 className="card-title">
                        <FontAwesomeIcon icon={faList} /> User List
                        </h3>
                        <div class="card-tools">
                        <InputGroup size="sm">
                            <FormControl
                            placeholder="Search"
                            name="search"
                            value={search}
                            className={"info-border bg-light text-white"}
                            onChange={this.searchChange}
                            />
                            <InputGroup.Append>
                            <Button
                                size="sm"
                                variant="outline-info"
                                type="button"
                                onClick={this.searchData}
                            >
                                <FontAwesomeIcon icon={faSearch} />
                            </Button>
                            <Button
                                size="sm"
                                variant="outline-danger"
                                type="button"
                                onClick={this.cancelSearch}
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </Button>
                            </InputGroup.Append>
                        </InputGroup>
                        </div>
                    </div>
                    <div className="card-body table-responsive p-0">
                        <table class="table table-striped">
                        <thead>
                            <tr>
                                <th onClick={this.sortData}>
                                ID{" "}
                                <div
                                    className={
                                    this.state.sortDir === "asc"
                                        ? "arrow arrow-up"
                                        : "arrow arrow-down"
                                    }
                                >
                                    {" "}
                                </div>
                                </th>
                                <th>Name</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                        {roles.length === 0 ? (
                            <tr align="center">
                            <td colSpan="7">No Role Available.</td>
                            </tr>
                        ) : (
                            roles.map((role) => (
                            <tr key={role.id}>
                                <td>{role.id}</td>
                                <td>{role.name}</td>
                                <td>
                                <ButtonGroup>
                                    <Link
                                    to={"role-edit/" + role.id}
                                    className="btn btn-sm btn-outline-primary"
                                    >
                                    <FontAwesomeIcon icon={faEdit} />
                                    </Link>{" "}
                                    <Button
                                    size="sm"
                                    variant="outline-danger"
                                    onClick={()=>this.toggleModal(role.id)}
                                    >
                                    <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </ButtonGroup>
                                </td>
                            </tr>
                            ))
                        )}
                        </tbody>
                        </table>
                    </div>
                    <div class="card-footer clearfix">
                        <div style={{ float: "left" }}>
                        Showing Page {currentPage} of {totalPages}
                        </div>
                        <div style={{ float: "right" }}>
                        <InputGroup size="sm">
                            <InputGroup.Prepend>
                            <Button
                                type="button"
                                variant="outline-info"
                                disabled={currentPage === 1 ? true : false}
                                onClick={this.firstPage}
                            >
                                <FontAwesomeIcon icon={faFastBackward} /> First
                            </Button>
                            <Button
                                type="button"
                                variant="outline-info"
                                disabled={currentPage === 1 ? true : false}
                                onClick={this.prevPage}
                            >
                                <FontAwesomeIcon icon={faStepBackward} /> Prev
                            </Button>
                            </InputGroup.Prepend>
                            <FormControl
                            className={"page-num bg-light"}
                            name="currentPage"
                            value={currentPage}
                            onChange={this.changePage}
                            />
                            <InputGroup.Append>
                            <Button
                                type="button"
                                variant="outline-info"
                                disabled={currentPage === totalPages ? true : false}
                                onClick={this.nextPage}
                            >
                                <FontAwesomeIcon icon={faStepForward} /> Next
                            </Button>
                            <Button
                                type="button"
                                variant="outline-info"
                                disabled={currentPage === totalPages ? true : false}
                                onClick={this.lastPage}
                            >
                                <FontAwesomeIcon icon={faFastForward} /> Last
                            </Button>
                            </InputGroup.Append>
                        </InputGroup>
                        </div>
                    </div>
                </div> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bookObject: state.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteRole: (roleId) => dispatch(deleteRole(roleId)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleList));
