import React from "react";
import { Route, Redirect } from "react-router-dom";

import AppHeader from "../components/Layout/Header.js";
import AppSidebar from "../components/Layout/Sidebar";
import AppFooter from "../components/Layout/Footer.js";

function PrivateRoute ({children, ...rest}){

    return (
      <div className="wrapper">
        <AppHeader />
        <AppSidebar />
        <Route
          {...rest}
          render={() => {
            if (localStorage.jwtToken) {
              return children;
            } else {
              return <Redirect to="/login" />;
            }
          }}
        />
        <AppFooter />
      </div>
      );
  }

  export default PrivateRoute;