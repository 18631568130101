import React from 'react';
import { useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { logoutUser } from "../../services/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
    // const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const logout = () => {
    dispatch(logoutUser());
  };
    return (
        <div>
            <nav className="main-header navbar navbar-expand navbar-green navbar-dark">
                {/* Left navbar links */}
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="/#" role="button"><i className="fas fa-bars" /></a>
                    </li>
                    <li className="nav-item d-none d-sm-inline-block">
                        <NavLink to="/home" className="nav-link">
                            HOME
                        </NavLink>
                    </li>
                </ul>
                {/* Right navbar links */}
                <ul className="navbar-nav ml-auto">
                
                {/* Notifications Dropdown Menu */}
                    {/* <li className="nav-item dropdown">
                        <a className="nav-link" data-toggle="dropdown" href="/#">
                        <i className="far fa-bell" />
                        <span className="badge badge-warning navbar-badge">15</span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                        <span className="dropdown-item dropdown-header">15 Notifications</span>
                        <div className="dropdown-divider" />
                        <a href="/#" className="dropdown-item">
                            <i className="fas fa-envelope mr-2" /> 4 new messages
                            <span className="float-right text-muted text-sm">3 mins</span>
                        </a>
                        <div className="dropdown-divider" />
                        <a href="/#" className="dropdown-item">
                            <i className="fas fa-users mr-2" /> 8 friend requests
                            <span className="float-right text-muted text-sm">12 hours</span>
                        </a>
                        <div className="dropdown-divider" />
                        <a href="/#" className="dropdown-item">
                            <i className="fas fa-file mr-2" /> 3 new reports
                            <span className="float-right text-muted text-sm">2 days</span>
                        </a>
                        <div className="dropdown-divider" />
                        <a href="/#" className="dropdown-item dropdown-footer">See All Notifications</a>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-widget="fullscreen" href="/#" role="button">
                        <i className="fas fa-expand-arrows-alt" />
                        </a>
                    </li> */}
                    <li className="nav-item d-none d-sm-inline-block">          
                        <Link to={"/login"} className="nav-link" onClick={logout}>
                            <FontAwesomeIcon icon={faSignOutAlt} /> Logout
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default Header;
