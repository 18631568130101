import * as BT from "./companyTypeTypes";
import axios from "axios";
import GLOBALS from '../../utils/Globals';

export const fetchCompanyType = (companyTypeId) => {
  return (dispatch) => {
    dispatch({
      type: BT.FETCH_COMPANYTYPE_REQUEST,
    });
    axios
      .get(GLOBALS.BASE_URL + "/companiestype/" + companyTypeId,{headers: {
        "BCI-Key": GLOBALS.BCI_KEY
      }})
      .then((response) => {
        dispatch(companyTypeSuccess(response.data));
        localStorage.setItem("jwtToken", response.data.token);
      })
      .catch((error) => {
        dispatch(companyTypeFailure(error));
      });
  };
};

export const saveCompanyType = (companyType) => {
  return (dispatch) => {
    dispatch({
      type: BT.SAVE_COMPANYTYPE_REQUEST,
    });

    axios
      .post(GLOBALS.BASE_URL + "/companytype/register", companyType,{headers: {
        "BCI-Key": GLOBALS.BCI_KEY
      }})
      .then((response) => {

        dispatch(companyTypeSuccess(response.data));
        localStorage.setItem("jwtToken", response.data.token);
      })
      .catch((error) => {
        dispatch(companyTypeFailure(error));
      });
  };
};

export const deleteCompanyType = (companyTypeId) => {
  return (dispatch) => {
    dispatch({
      type: BT.DELETE_COMPANYTYPE_REQUEST,
    });
    
    axios
      .delete(GLOBALS.BASE_URL + "/companytype/delete/" + companyTypeId)
      .then((response) => {
        dispatch(companyTypeDeleteSuccess(response.data));
        localStorage.setItem("jwtToken", response.data.token);
      })
      .catch((error) => {
        dispatch(companyTypeFailure(error));
      });
  };
};

const companyTypeSuccess = (companyType) => {
  return {
    type: BT.COMPANYTYPE_SUCCESS,
    payload: companyType,
  };
};

const companyTypeFailure = (error) => {
  return {
    type: BT.COMPANYTYPE_FAILURE,
    payload: error,
  };
};

const companyTypeDeleteSuccess = (companyType) => {
  return {
    type: BT.COMPANYTYPE_DELETE,
    payload: companyType,
  };
};
