import * as BT from "./companyTypes";

const initialState = {
  company: "",
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case BT.SAVE_COMPANY_REQUEST:
    case BT.FETCH_COMPANY_REQUEST:
    case BT.UPDATE_COMPANY_REQUEST:
    case BT.DELETE_COMPANY_REQUEST:
    case BT.COMPANY_SUCCESS:
      return {
        company: action.payload,
        error: "",
      };
    case BT.COMPANY_FAILURE:
      return {
        company: "",
        error: action.payload,
      };
    case BT.COMPANY_DELETE:
      return {
        company: action.payload,
        error: "",
      };
    default:
      return state;
  }
};

export default reducer;
