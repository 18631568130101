import * as BT from "./roleTypes";
import axios from "axios";
import GLOBALS from '../../utils/Globals';

export const fetchRole = (roleId) => {
  return (dispatch) => {
    dispatch({
      type: BT.FETCH_ROLE_REQUEST,
    });
    axios
      .get(GLOBALS.BASE_URL + "/roles/" + roleId,{headers: {
        "BCI-Key": GLOBALS.BCI_KEY
      }})
      .then((response) => {
        dispatch(roleSuccess(response.data));
      })
      .catch((error) => {
        dispatch(roleFailure(error));
      });
  };
};

export const saveRole = (roles) => {
  return (dispatch) => {
    dispatch({
      type: BT.SAVE_ROLE_REQUEST,
    });
    axios
      .post(GLOBALS.BASE_URL + "/role/register", roles,{headers: {
        "BCI-Key": GLOBALS.BCI_KEY
      }})
      .then((response) => {
        dispatch(roleSuccess(response.data));
      })
      .catch((error) => {
        dispatch(roleFailure(error));
      });
  };
};

export const deleteRole = (roleId) => {
  return (dispatch) => {
    dispatch({
      type: BT.DELETE_ROLE_REQUEST,
    });
    axios
      .delete(GLOBALS.BASE_URL + "/role/delete/" + roleId)
      .then((response) => {
        dispatch(roleDeleteSuccess(response.data));
      })
      .catch((error) => {
        dispatch(roleFailure(error));
      });
  };
};

const roleSuccess = (role) => {
  return {
    type: BT.ROLE_SUCCESS,
    payload: role,
  };
};

const roleFailure = (error) => {
  return {
    type: BT.ROLE_FAILURE,
    payload: error,
  };
};

const roleDeleteSuccess = (role) => {
  return {
    type: BT.ROLE_DELETE,
    payload: role,
  };
};
