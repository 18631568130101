import React, { useEffect } from "react";
import "../../App.css";
import { NavLink } from "react-router-dom";
import { loadTree } from "../../utils/MenuTreeHelper.js";
import { useSelector } from "react-redux";

const Sidebar = () => {
  useEffect(() => {
    loadTree();
  });

  // const roles = JSON.parse(localStorage.repoRole);
  const userRole = localStorage.role;
  const auth = useSelector((state) => state.auth);

  const menuTicket = (
    <li className="nav-item" key="menuTicket">
      <a href="/#" className="nav-link">
        <i className="nav-icon fas fa-clipboard-list" />
        <p>
          Ticket <i className="fas fa-angle-left right" />
        </p>
      </a>
      <ul className="nav nav-treeview">
        <li className="nav-item">
          <NavLink to="/document-list" className="nav-link">
            <i className="far fas fa-caret-right nav-icon" />
            <p>
              Fronting Tickets
              {/* <span className="badge badge-info right">2</span> */}
            </p>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/channeling-list" className="nav-link">
            <i className="far fas fa-caret-right nav-icon" />
            <p>
              Channeling Tickets
              {/* <span className="badge badge-info right">2</span> */}
            </p>
          </NavLink>
        </li>
        {/* <li className="nav-item">
                <NavLink to="/document-asuransi" className="nav-link">
                    <i className="far fas fa-caret-right nav-icon" />
                    <p>Verify Video Asuransi
                    </p>
                </NavLink>
                </li> */}
      </ul>
    </li>
  );

  const menuTicketFronting = (
    <li className="nav-item" key="menuTicket">
      <a href="/#" className="nav-link">
        <i className="nav-icon fas fa-clipboard-list" />
        <p>
          Ticket <i className="fas fa-angle-left right" />
        </p>
      </a>
      <ul className="nav nav-treeview">
        <li className="nav-item">
          <NavLink to="/document-list" className="nav-link">
            <i className="far fas fa-caret-right nav-icon" />
            <p>
              Fronting Tickets
            </p>
          </NavLink>
        </li>
      </ul>
    </li>
  );

  const menuTicketChanneling = (
    <li className="nav-item" key="menuTicket">
      <a href="/#" className="nav-link">
        <i className="nav-icon fas fa-clipboard-list" />
        <p>
          Ticket <i className="fas fa-angle-left right" />
        </p>
      </a>
      <ul className="nav nav-treeview">
        <li className="nav-item">
          <NavLink to="/document-list" className="nav-link">
            <i className="far fas fa-caret-right nav-icon" />
            <p>
              Channeling Tickets
            </p>
          </NavLink>
        </li>
      </ul>
    </li>
  );

  const menuTicketHeadAo = (
    <li className="nav-item" key="menuTicket">
      <a href="/#" className="nav-link">
        <i className="nav-icon fas fa-clipboard-list" />
        <p>
          Ticket <i className="fas fa-angle-left right" />
        </p>
      </a>
      <ul className="nav nav-treeview">
        <li className="nav-item">
          <NavLink to="/document-assign" className="nav-link">
            <i className="far fas fa-caret-right nav-icon" />
            <p>Ticket Assign</p>
          </NavLink>
        </li>
        {/* <li className="nav-item">
                <NavLink to="/document-asuransi" className="nav-link">
                    <i className="far fas fa-caret-right nav-icon" />
                    <p>Verify Video Asuransi
                    </p>
                </NavLink>
                </li> */}
      </ul>
    </li>
  );

  const menuCompany = (
    <li className="nav-item" key="menuCompany">
      <a href="/#" className="nav-link">
        <i className="nav-icon fas fa-building" />
        <p>
          Company <i className="fas fa-angle-left right" />
        </p>
      </a>
      <ul className="nav nav-treeview">
        <li className="nav-item">
          <NavLink to="/company-list" className="nav-link">
            <i className="far fas fa-caret-right nav-icon" />
            <p>Company List</p>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/company" className="nav-link">
            <i className="far fas fa-caret-right nav-icon" />
            <p>New Company</p>
          </NavLink>
        </li>
      </ul>
    </li>
  );

  const menuRole = (
    <li className="nav-item" key="menuRole">
      <a href="/#" className="nav-link">
        <i className="nav-icon fas fa-user-tag" />
        <p>
          Role <i className="fas fa-angle-left right" />
        </p>
      </a>
      <ul className="nav nav-treeview">
        <li className="nav-item">
          <NavLink to="/role-list" className="nav-link">
            <i className="far fas fa-caret-right nav-icon" />
            <p>Role List</p>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/roles" className="nav-link">
            <i className="far fas fa-caret-right nav-icon" />
            <p>New Role</p>
          </NavLink>
        </li>
      </ul>
    </li>
  );

  const menuUser = (
    <li className="nav-item" key="menuUser">
      <a href="/#" className="nav-link">
        <i className="nav-icon fas fa-user-friends" />
        <p>
          Users <i className="fas fa-angle-left right" />
        </p>
      </a>
      <ul className="nav nav-treeview">
        <li className="nav-item">
          <NavLink to="/user-list" className="nav-link">
            <i className="far fas fa-caret-right nav-icon" />
            <p>User List</p>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/users" className="nav-link">
            <i className="far fas fa-caret-right nav-icon" />
            <p>New User</p>
          </NavLink>
        </li>
      </ul>
    </li>
  );

  const menuUserByCompany = (
    <li className="nav-item" key="menuUser">
      <a href="/#" className="nav-link">
        <i className="nav-icon fas fa-user-friends" />
        <p>
          Users <i className="fas fa-angle-left right" />
        </p>
      </a>
      <ul className="nav nav-treeview">
        <li className="nav-item">
          <NavLink to="/user-list" className="nav-link">
            <i className="far fas fa-caret-right nav-icon" />
            <p>User List</p>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/users" className="nav-link">
            <i className="far fas fa-caret-right nav-icon" />
            <p>New User</p>
          </NavLink>
        </li>
      </ul>
    </li>
  );

  const menuChangePassword = (
    <li className="nav-item" key="menuChangePassword">
      <a href="/#" className="nav-link">
        <i className="nav-icon fas fa-user-friends" />
        <p>
          User <i className="fas fa-angle-left right" />
        </p>
      </a>
      <ul className="nav nav-treeview">
        <li className="nav-item">
          <NavLink
            to={{ pathname: "/change-password/" + localStorage.userId }}
            className="nav-link"
          >
            <i className="far fas fa-caret-right nav-icon" />
            <p>
              Change Password
              {/* <span className="badge badge-info right">2</span> */}
            </p>
          </NavLink>
        </li>
      </ul>
    </li>
  );

  const menuReport = (
    <li className="nav-item" key="menuReport">
      <a href="/#" className="nav-link">
        <i className="nav-icon fas fa-folder" />
        <p>
          Report <i className="fas fa-angle-left right" />
        </p>
      </a>
      <ul className="nav nav-treeview">
        <li className="nav-item">
          <NavLink to="/report-ticket" className="nav-link">
            <i className="far fas fa-caret-right nav-icon" />
            <p>Ticket Report</p>
          </NavLink>
        </li>
      </ul>
    </li>
  );

  const menuCompanyType = (
    <li className="nav-item" key="menuCompanyType">
      <a href="/#" className="nav-link">
        <i className="nav-icon fas fa-building" />
        <p>
          Company Type
          <i className="fas fa-angle-left right" />
        </p>
      </a>
      <ul className="nav nav-treeview">
        <li className="nav-item">
          <NavLink to="/companyType-list" className="nav-link">
            <i className="far fas fa-caret-right nav-icon" />
            <p>Company Type List</p>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/companyType" className="nav-link">
            <i className="far fas fa-caret-right nav-icon" />
            <p>New Company Type</p>
          </NavLink>
        </li>
      </ul>
    </li>
  );

  let menu = "";
  if (userRole === "ROLE_ADMIN") {
    menu = [
      menuTicket,
      menuCompanyType,
      menuCompany,
      menuRole,
      menuUser,
      menuReport,
    ];
  }
  if (userRole === "ROLE_FRONTING_ADMIN") {
    menu = [menuTicketFronting, menuUserByCompany, menuChangePassword];
  }
  if (userRole === "ROLE_CHANNELING_ADMIN") {
    menu = [menuTicketChanneling, menuUserByCompany, menuChangePassword];
  }
  if (userRole === "ROLE_FRONTING_VERIFIER") {
    menu = [menuTicketFronting, menuChangePassword];
  }
  if (userRole === "ROLE_CHANNELING_VERIFIER") {
    menu = [menuTicketChanneling, menuChangePassword];
  }
  if (userRole === "ROLE_BCI_AO") {
    menu = [menuTicket, menuChangePassword];
  }
  if (userRole === "ROLE_HEAD_AO") {
    menu = [menuTicketHeadAo, menuChangePassword, menuReport];
  }

  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <div className="brand-link ">
          <img
            src="../dist/img/logo_only.jpeg"
            alt="BCI Logo"
            className="brand-image"
            style={{ opacity: ".8  " }}
          />
          <span className="brand-text font-weight-light">BCI-DAPEN</span>
        </div>
        <div className="sidebar">
          <div className="user-panel mt-1 pb-1 mb-1 d-flex">
            <div className="image">
              <img
                src="dist/img/boxed-bg.png"
                className="img-circle elevation-2"
                alt="User"
              />
              <div className="pic-initial">
                <h5>{auth.username.substr(0, 1)}</h5>
              </div>
            </div>
            <div className="info">
              <NavLink as={NavLink} to="/home" className="d-block">
                {auth.username}
              </NavLink>
            </div>
          </div>

          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-header"> Menu</li>

              {menu}
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
