import React, { Component } from "react";
import { connect } from "react-redux";
import {assignTicket, } from "../../services/index";

import "./../../assets/css/Style.css";
import {
  Card,
  Table,
  Button,
  InputGroup,
  FormControl,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faStepBackward,
  faFastBackward,
  faStepForward,
  faFastForward,
} from "@fortawesome/free-solid-svg-icons";
import MyToast from "../../utils/MyToast";
import axios from "axios";
import GLOBALS from '../../utils/Globals';
import { withRouter } from "react-router";

class DocumentAssign extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      documents: [],
      usersAO: [],
      userAo: "",
      roleId: 5, //BCI AO
      search: "",
      currentPage: 1,
      documentPerPage: 5,
      sortDir: "asc",
      textReason: "",
      setStatus: "",
      assignParam: "",
      arrStatus: [{id:'pending', name:'Pending'},
        {id:'verify_front', name:'Verify Front'},
        {id:'ready_to_los', name:'Ready to LOS'},
        {id:'finish', name:'Finish'},
        {id:'reject', name:'Reject'},
        {id:'failed', name:'Failed'},
        {id:'los_done', name:'LOS Done'}],
    };
  }

  sortData = () => {
    setTimeout(() => {
      this.state.sortDir === "asc"
        ? this.setState({ sortDir: "desc" })
        : this.setState({ sortDir: "asc" });
      this.findAllDocument(this.state.currentPage);
    }, 500);
  };

  componentDidMount() {
    this.findAllDocument(this.state.currentPage);
    this.fetchUserRole(this.state.roleId) //5 for BCI_AO;
  }

  findAllDocument(currentPage) {
    currentPage -= 1;
    axios
      .get(
        GLOBALS.BASE_URL + "/documents?pageNumber=" +
        currentPage +
        "&pageSize=" +
        this.state.documentPerPage +
        "&sortBy=id&sortDir=" +
        this.state.sortDir + 
        "&status=all" , {
        headers: {
          "BCI-Key": GLOBALS.BCI_KEY
        }
      }
      )
      .then((response) => response.data)
      .then((data) => {
        this.setState({
          
          documents: data.result.content,
          totalPages: data.result.totalPages,
          totalElements: data.result.totalElements,
          currentPage: data.result.number + 1,
        });
        localStorage.setItem("jwtToken", data.token);
      })
      .catch((error) => {
        console.log(error);
        localStorage.removeItem("jwtToken");
        this.props.history.push("/login");
      });
  }

  fetchUserRole(roleId) {
    axios
      .get(
        GLOBALS.BASE_URL + "/users/find/" + roleId , {
        headers: {
          "BCI-Key": GLOBALS.BCI_KEY
        }
      }
      )
      .then((response) => response.data)
      .then((data) => {
        let dataResp = JSON.parse(data.result);
        this.setState({ usersAO: dataResp.roleAo});
        localStorage.setItem("jwtToken", data.token);
      })
      .catch((error) => {
        console.log(error);
        localStorage.removeItem("jwtToken");
        this.props.history.push("/login");
      });
  }

  changePage = (event) => {
    let targetPage = parseInt(event.target.value);
    if (this.state.search) {
      this.searchData(targetPage);
    } else {
      this.findAllDocument(targetPage);
    }
    this.setState({
      [event.target.name]: targetPage,
    });
  };

  firstPage = () => {
    let firstPage = 1;
    if (this.state.currentPage > firstPage) {
      if (this.state.search) {
        this.searchData(firstPage);
      } else {
        this.findAllDocument(firstPage);
      }
    }
  };

  prevPage = () => {
    let prevPage = 1;
    if (this.state.currentPage > prevPage) {
      if (this.state.search) {
        this.searchData(this.state.currentPage - prevPage);
      } else {
        this.findAllDocument(this.state.currentPage - prevPage);
      }
    }
  };

  lastPage = () => {
    let condition = Math.ceil(
      this.state.totalElements / this.state.documentPerPage
    );
    if (this.state.currentPage < condition) {
      if (this.state.search) {
        this.searchData(condition);
      } else {
        this.findAllDocument(condition);
      }
    }
  };

  nextPage = () => {
    if (
      this.state.currentPage <
      Math.ceil(this.state.totalElements / this.state.documentPerPage)
    ) {
      if (this.state.search) {
        this.searchData(this.state.currentPage + 1);
      } else {
        this.findAllDocument(this.state.currentPage + 1);
      }
    }
  };

  searchChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  cancelSearch = () => {
    this.setState({ search: "" });
    this.findAllDocument(this.state.currentPage);
  };

  searchData = (currentPage) => {
    currentPage -= 1;
    axios
      .get(
        "http://localhost:8081/rest/books/search/" +
        this.state.search +
        "?page=" +
        currentPage +
        "&size=" +
        this.state.documentPerPage
      )
      .then((response) => response.data)
      .then((data) => {
        this.setState({
          books: data.result.content,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          currentPage: data.number + 1,
        });
      });
  };

  state = {
    isOpen: false
  };

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });
  
  openModalAsign = (itemAssign) => this.setState({ isOpenAssign: true, assignParam: itemAssign });
  closeModalAsign = () => this.setState({ isOpenAssign: false });

  toggleModal = (itemId) => {
    this.setState({
      isOpen: !this.state.isOpen,
      setStatus: itemId
    });
  };

  userChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  funcAssign = (userAo) => {
    const assignRequest = {
        documentId: this.state.assignParam,
        email: this.state.userAo,
      };

    this.props.assignTicket(assignRequest);
    setTimeout(() => {
        this.setState({ isOpenAssign: false });
        this.findAllDocument(this.state.currentPage);
    }, 2000);
    // this.docList();
  };
  
  render() {
    const { documents, currentPage, totalPages, arrStatus, usersAO, userAo } = this.state;
    
    function b(idToSearch) {
      return arrStatus.filter(item => {
        return item.id === idToSearch
      }).map(e => e.name)
    };

    if(typeof documents ==='undefined'){
      return(
        documents: 0
      )
    }

    return (
      <div>
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">                    
                    <li className="breadcrumb-item">Home</li>
                    <li className="breadcrumb-item active">List Documents</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <Modal show={this.state.isOpenAssign}  >
              <Modal.Body>
                <h3>Assign this ticket to : </h3>
                <div className="form-group row">
                    <label htmlFor="selectUser" className="col-sm-2 col-form-label">Account Officer</label>
                    <div className="col-sm-6" >
                    <select className="form-control select2bs4" onChange={this.userChange} value={userAo} name="userAo" required>
                        <option defaultValue="Select Account Officer" >Select Account Officer</option>
                        {usersAO.map((usersAO) => (
                        <option key={usersAO.id} value={usersAO.email}>{usersAO.email}</option>
                        ))}
                    </select>
                    </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                    <Button variant="success" onClick={()=> this.funcAssign(userAo)}>Yes</Button>
                    <Button variant="secondary" onClick={()=> this.closeModalAsign()}>Cancel</Button>
              </Modal.Footer>
          </Modal>

          <div className="content">
            <div className="container-fluid">
              <div style={{ display: this.state.show ? "block" : "none" }}>
                <MyToast
                  show={this.state.show}
                  message={"Status Update Successfully."}
                  type={"success"}
                />
              </div>

              <Card className={"border"}>
                <Card.Header>
                  <div style={{ float: "left" }}>
                    <FontAwesomeIcon icon={faList} /> List Documents
                  </div>
                </Card.Header>
                <Card.Body className="card-body table-responsive p-0">
                  <Table bordered hover striped variant="light">
                    <thead>
                      <tr>
                        <th>Ticket No</th>
                        <th>Name</th>
                        <th>NIK</th>
                        <th>Status</th>
                        <th>Created Date</th>
                        <th>Modified Date</th>
                        <th>Account Officer</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {documents.length === 0 ? (
                        <tr align="center">
                          <td colSpan="7">No Document Available.</td>
                        </tr>
                      ) : (
                        documents.map((document) => (
                          <tr key={document.id}>
                            <td>{document.ticketNo}</td>
                            <td>{document.name}</td>
                            <td>{document.nik}</td>
                            <td>
                              {b(document.status)}
                            </td>
                            <td>{document.createdDate}</td>
                            <td>{document.modifiedDate}</td>
                            <td>{document.accountOfficer}</td>

                            <td>
                                <Button 
                                    className="btn btn-outline-info"
                                    variant="outline-info" 
                                    size="sm"
                                    onClick={()=> this.openModalAsign(document.id)}
                                    >ASSIGN
                                </Button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </Card.Body>
                {documents.length > 0 ? (
                  <Card.Footer>
                    <div style={{ float: "left" }}>
                      Showing Page {currentPage} of {totalPages}
                    </div>
                    <div style={{ float: "right" }}>
                      <InputGroup size="sm">
                        <InputGroup.Prepend>
                          <Button
                            type="button"
                            variant="outline-info"
                            disabled={currentPage === 1 ? true : false}
                            onClick={this.firstPage}
                          >
                            <FontAwesomeIcon icon={faFastBackward} /> First
                          </Button>
                          <Button
                            type="button"
                            variant="outline-info"
                            disabled={currentPage === 1 ? true : false}
                            onClick={this.prevPage}
                          >
                            <FontAwesomeIcon icon={faStepBackward} /> Prev
                          </Button>
                        </InputGroup.Prepend>
                        <FormControl
                          className={"page-num bg-light"}
                          name="currentPage"
                          value={currentPage}
                          onChange={this.changePage}
                        />
                        <InputGroup.Append>
                          <Button
                            type="button"
                            variant="outline-info"
                            disabled={currentPage === totalPages ? true : false}
                            onClick={this.nextPage}
                          >
                            <FontAwesomeIcon icon={faStepForward} /> Next
                          </Button>
                          <Button
                            type="button"
                            variant="outline-info"
                            disabled={currentPage === totalPages ? true : false}
                            onClick={this.lastPage}
                          >
                            <FontAwesomeIcon icon={faFastForward} /> Last
                          </Button>
                        </InputGroup.Append>
                      </InputGroup>
                    </div>
                  </Card.Footer>
                ) : null}
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
}

const mapStateToProps = (state) => {
  return {
    bookObject: state.document,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    assignTicket: (userAo) => dispatch(assignTicket(userAo)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DocumentAssign));
