import React, { Component } from "react";
// import { useSelector } from "react-redux";
import authToken from "../../utils/authToken";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import GLOBALS from '../../utils/Globals';
import { Link } from "react-router-dom";
import {
  Button,
  Modal,
} from "react-bootstrap";
import "../../App.css";


class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: [],
      userName: "",
      isOpenModal: false,
    };
  }


  componentDidMount() {
    if (localStorage.jwtToken) {
      authToken(localStorage.jwtToken);
    }
    
    if(localStorage.respCode === "06"){
      this.setState({
        isOpenModal: true
      });
    }

    this.fetchDocument();    
  }

  toChangePass = () => {
    return this.props.history.push("/change-password/" + localStorage.userId);
  };

  fetchDocument = () => {
    axios
      .get(GLOBALS.BASE_URL + "/documents/v2" , {
       
    })
      .then((response) => response.data)
      .then((data) => {
        this.setState({
          documents: data.result,
          userName: this.props.auth.username,
        });
      })
      .catch((error) => {
        console.log(error);
        localStorage.removeItem("jwtToken");
        // this.props.history.push("/login");
      });
    }

  render() {
    const { documents, userName } = this.state;

    return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Welcome {userName}</h1>
              </div>
              {/* <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="/#">Home</a></li>
                </ol>
              </div> */}
            </div>
          </div>
        </div>

        <Modal show={this.state.isOpenModal}  >
              <Modal.Body>
                <h3>Kata sandi anda sudah kadaluarsa Harap ubah kata sandi anda !</h3>
              </Modal.Body>
              <Modal.Footer>
                    <Button variant="warning" onClick={()=> this.toChangePass()}>Ubah kata sandi</Button>
              </Modal.Footer>
          </Modal>

        <section className="content">
          <div className="container-fluid">
            <div className="row">

              <div className="col-lg-3 col-6">
                <div className="small-box bg-light">
                  <div className="inner md">
                    {typeof documents ==='undefined' ? (
                      <h3>0</h3>
                    ): (
                      <Link to={"document-status/pending"}>
                        <h3 className="bg-light">{documents.filter((item) => item.status === 'pending').length}</h3>
                      </Link>
                    )}
                    
                    <p>New Ticket</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-file"></i>
                  </div>
                  {/* <a href="/#" className="small-box-footer">More Details <i className="fas fa-arrow-circle-right" /></a> */}
                </div>
              </div>
              
              <div className="col-lg-3 col-6">
                <div className="small-box bg-info" data-toggle="tooltip" title="documents has been verifying by Fronting Verifier">
                  <div className="inner">
                    {typeof documents ==='undefined' ? (
                      <h3>0</h3>
                    ): (
                      <Link to={"document-status/verify_front"}>
                        <h3 className="bg-info">{documents.filter((item) => item.status === 'verify_front').length}</h3>
                      </Link>
                    )}

                    {/* <sup style={{fontSize: 20}}>%</sup> */}
                    <p>Verified by Front</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-file-medical-alt"></i>
                  </div>
                  {/* <a href="/#" className="small-box-footer">More Details <i className="fas fa-arrow-circle-right" /></a> */}
                </div>
              </div>
              
              <div className="col-lg-3 col-6">
                <div className="small-box bg-primary" data-toggle="tooltip" title="documents has been verifying by BCI Account Officer, document is ready for LOS process">
                  <div className="inner">
                    {typeof documents ==='undefined' ? (
                      <h3>0</h3>
                    ): (
                      <Link to={"document-status/ready_to_los"}>
                        <h3 className="bg-primary">{documents.filter((item) => item.status === 'ready_to_los').length}</h3>
                      </Link>
                    )}  
                    <p>Verify by Capital</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-file-contract"></i>
                  </div>
                    {/* <a href="/#" className="small-box-footer">More Details <i className="fas fa-arrow-circle-right" /></a> */}
                </div>
              </div>

              <div className="col-lg-3 col-6">
                <div className="small-box bg-danger" data-toggle="tooltip" title="documents cannot be verified because there is blur or unreadable">
                  <div className="inner">
                    {typeof documents ==='undefined' ? (
                      <h3>0</h3>
                    ): (
                      <Link to={"document-status/reject"}>
                        <h3 className="bg-danger">{documents.filter((item) => item.status === 'reject').length}</h3>
                      </Link>
                    )}

                    {/* <sup style={{fontSize: 20}}>%</sup> */}
                    <p>Reject</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-file-excel"></i>
                  </div>
                  {/* <a href="/#" className="small-box-footer">More Details <i className="fas fa-arrow-circle-right" /></a> */}
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="small-box bg-success" data-toggle="tooltip" title="LOS processing has been done">
                  <div className="inner">
                    {typeof documents ==='undefined' ? (
                      <h3>0</h3>
                    ): (
                      <Link to={"document-status/finish"}>
                        <h3 className="bg-success">{documents.filter((item) => item.status === 'finish').length}</h3>
                      </Link>
                    )}

                    {/* <sup style={{fontSize: 20}}>%</sup> */}
                    <p>Finish</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-file-signature"></i>
                  </div>
                  {/* <a href="/#" className="small-box-footer">More Details <i className="fas fa-arrow-circle-right" /></a> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    bookObject: state.book,
    auth: state.auth,
  };
};
  
const mapDispatchToProps = (dispatch) => {
  return {
    // fetchDocument: () => dispatch(fetchDocument()),
    
  };
};
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
  
