import React, { Component } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUndo,
  faUserPlus,
  faEdit,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { 
  saveRole,
  fetchRole,
} from "../../services/index";
import MyToast from "../../utils/MyToast";
import { withRouter } from "react-router";


class RoleRegister extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  initialState = {
    name: "",
    show: false,
    roles: [],
  };
  
  componentDidMount() {
    const roleId = +this.props.match.params.id;
    if (roleId) {
      this.findRoleById(roleId);
    } else {
      this.setState({email: ''});
    }
  }

  findRoleById = (roleId) => {
    this.props.fetchRole(roleId);
    setTimeout(() => {
      let roles = this.props.bookObject.roles;
      if (roles != null) {
        this.setState({
          id: roles.id,
          name: roles.name,
        });
      }
    }, 1000);
  };


  resetRole = () => {
    this.setState(() => this.initialState);
  };

  roleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  
  roleList = () => {
    return this.props.history.push("/role-list");
  };

  submitRole = (event) => {
    event.preventDefault();

    const roles = {
      id: this.state.id,
      name: this.state.name,
    };

    this.props.saveRole(roles);
    setTimeout(() => {
      if (this.props.bookObject.roles != null) {
        this.setState({ show: true, method: "post" });
        setTimeout(() => this.setState({ show: false }), 2000);
        setTimeout(() => { 
          this.roleList();
      }, 3000)
      } else {
        this.setState({ show: false });
      }
    }, 2000);
  };
  

  render(){
    const { name, show, message } =
      this.state;

    return (
      <div>
        <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    {/* <h1 className="m-0">Welcome </h1> */}
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">                    
                      <li className="breadcrumb-item">Home</li>
                      <li className="breadcrumb-item active">Role</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <MyToast
            show={this.state.show}
            message={
              this.state.id
                ? "User Updated Successfully."
                : "User Registered Successfully."
            }
            type={"success"}
            />
            <div className="content">
              <div className="container-fluid">
                <div style={{ display: show ? "block" : "none" }}>
                  <MyToast show={show} message={message} type={"success"} />
                </div>
                <div className="card card-light">
                  <div className="card-header">
                    <h3 className="card-title">
                      <FontAwesomeIcon icon={this.state.id ? faEdit : faUserPlus} />{" "}
                      {this.state.id ? "Update Role" : "Register New Role"}
                    </h3>
                  </div>

                  <form 
                    onReset={this.resetRole}
                    onSubmit={this.submitRole}
                    id="formUserId"
                  >
                    <div className="card-body">
                        {/* <div className="form-group row">
                            <label htmlFor="inputName" className="col-sm-1 col-form-label">Id</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" name="name" value={id} disabled/>
                            </div>
                        </div> */}
                        <div className="form-group row">
                            <label htmlFor="inputName" className="col-sm-1 col-form-label">Name</label>
                            <div className="col-sm-10">
                                <input type="text" onChange={this.roleChange} className="form-control" name="name" value={name} placeholder="Enter Role Name" />
                            </div>
                        </div>
                    </div>

                    <div className="card-footer">
                      <button className="btn btn-success swalDefaultSuccess" type="submit" name="btnSubmit">
                          <FontAwesomeIcon icon={faSave} />{" "}
                          {this.state.id ? "Update" : "Register"}
                        </button>{" "}
                      <button size="md" class="btn btn-info" type="reset" name="btnReset">
                        <FontAwesomeIcon icon={faUndo} /> Reset
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bookObject: state.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveRole: (roles) => dispatch(saveRole(roles)),
    fetchRole: (roleId) => dispatch(fetchRole(roleId)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleRegister));
