import React, { Component } from "react";
import { connect } from "react-redux";
import {
    saveCompanyType,
    fetchCompanyType,
} from "../../services/index";
import { Card, Form, Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faPlusSquare,
  faUndo,
  faList,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import "../../App.css";
import MyToast from "../../utils/MyToast";
import { withRouter } from "react-router";


class CompanyType extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  initialState = {
    companyTypeName: "",
    show: false,
    errorField: [],
    errorMsg: "",
  };

  componentDidMount() {
    const companyTypeId = +this.props.match.params.id;
    if (companyTypeId) {
      this.findCompanyTypeById(companyTypeId);
    }
  }

  findCompanyTypeById = (companyTypeId) => {
    this.props.fetchCompanyType(companyTypeId);
    setTimeout(() => {
      let companyType = this.props.bookObject.companyType;
      if (companyType != null) {
        this.setState({
          id: companyType.id,
          companyTypeName: companyType.companyTypeName
        });
      }
    }, 1000);
  };

  resetCompanyType = () => {
    this.setState(() => this.initialState);
  };

  submitCompanyType = (event) => {
    event.preventDefault();

    var errors = [];
    var errorMsg = "";

    if (this.state.companyTypeName.length < 0) {
      errorMsg = "Company Type cannot empty";
      errors.push("companyTypeName");
    }

    this.setState({
      errorMsg: errorMsg,
      errorField: errors
    });

    if (errors.length > 0) {
      return false;
    }

    const companyType = {
      id: this.state.id,
      companyTypeName: this.state.companyTypeName
    };

    this.props.saveCompanyType(companyType);

    setTimeout(() => {

      if (this.props.bookObject.companyType != null) {
          this.setState({ show: true, method: "post" });
          setTimeout(() => this.setState({ show: false }), 2000);
          setTimeout(() => { 
          this.companyTypeList();
      }, 3000)
      } else {
        this.setState({ show: false });
      }
    }, 2000);
    this.setState(this.initialState);
  };

  companyTypeChange = (event) => {
    var key = event.target.name;
    var value = event.target.value;
    var obj = {};
    obj[key] = value;
    this.setState(obj);
    // this.setState({
    //   [event.target.name]: event.target.value,
    // });
    if(this.state.errorField.indexOf(key) !== -1){
      this.setState({errorField: []});
    }
  };
  
  companyTypeList = () => {
    return this.props.history.push("/companyType-list");
  };

  hasError(key) {
    return this.state.errorField.indexOf(key) !== -1;
  }

  render() {
    
    const { companyTypeName } =
      this.state;

    return (
      <div>
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  {/* <h1 className="m-0">Welcome </h1> */}
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">                    
                    <li className="breadcrumb-item">Home</li>
                    <li className="breadcrumb-item active">Company Type</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <MyToast
            show={this.state.show}
            message={
              this.state.id
                ? "Company Type Updated Successfully."
                : "Company Type Saved Successfully."
            }
            type={"success"}
          />

          <div className="content">
            <div className="container-fluid">
              <Card className={"border"}>
                <Card.Header>
                  <FontAwesomeIcon icon={this.state.id ? faEdit : faPlusSquare} />{" "}
                  {this.state.id ? "Update Company Type" : "Add New Company Type"}
                </Card.Header>
                <Form
                  onReset={this.resetCompanyType}
                  onSubmit={this.submitCompanyType}
                  id="bookFormId"
                >
                  <Card.Body>
                    <Form.Group as={Row} className="mb-3" controlId="formGridName">
                      <Form.Label column sm="1">Name</Form.Label>
                      <Col sm="11">
                        <Form.Control 
                          required
                          className={
                            this.hasError("companyTypeName")
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          autoComplete="off"
                          type="test"
                          name="companyTypeName"
                          value={companyTypeName || ''}
                          onChange={this.companyTypeChange}
                          placeholder="Enter Company Type Name"
                        />
                      </Col>
                    </Form.Group>

                    </Card.Body>
                    <Card.Footer style={{ textAlign: "right" }}>
                      <Button size="sm" variant="success" type="submit">
                        <FontAwesomeIcon icon={faSave} />{" "}
                        {this.state.id ? "Update" : "Save"}
                      </Button>{" "}
                      <Button size="sm" variant="info" type="reset">
                        <FontAwesomeIcon icon={faUndo} /> Reset
                      </Button>{" "}
                      <Button
                        size="sm"
                        variant="info"
                        type="button"
                        onClick={() => this.companyTypeList()}
                      >
                        <FontAwesomeIcon icon={faList} /> Company Type List
                      </Button>
                    </Card.Footer>
                  </Form>
                </Card>      
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bookObject: state.companyType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveCompanyType: (companyType) => dispatch(saveCompanyType(companyType)),
    fetchCompanyType: (companyTypeId) => dispatch(fetchCompanyType(companyTypeId)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyType));
