import axios from "axios";
import GLOBALS from "../utils/Globals";
const AUTH_URL = GLOBALS.BASE_URL + "/user/authenticate";
const authToken = (token) => {
  if (token) {
    axios.defaults.headers.common["BCI-Key"] = `${GLOBALS.BCI_KEY}`;
    axios.defaults.headers.common["Authorization"] = `${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
    delete axios.defaults.headers.common["BCI-Key"];

    const response = axios.post(
      AUTH_URL,
      {
        email: localStorage.email,
        password: localStorage.password,
      },
      {
        headers: {
          "BCI-Key": GLOBALS.BCI_KEY,
        },
      }
    );
    response.data = JSON.parse(response.data.result);
    localStorage.setItem("jwtToken", response.data.token);
    axios.defaults.headers.common["Authorization"] = response.data.token;
    axios.defaults.headers.common["BCI-Key"] = `${GLOBALS.BCI_KEY}`;
  }
};

export default authToken;
