import React, { Component } from "react";

import { connect } from "react-redux";
import { deleteCompanyType } from "../../services/index";

import "./../../assets/css/Style.css";
import {
  Card,
  Table,
  ButtonGroup,
  Button,
  InputGroup,
  FormControl,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faStepBackward,
  faFastBackward,
  faStepForward,
  faFastForward,
  //faSearch,
  //faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import axios from "axios";
import GLOBALS from '../../utils/Globals';
import { withRouter } from "react-router";


class CompanyTypeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companiesType: [],
      search: "",
      currentPage: 1,
      booksPerPage: 5,
      sortDir: "asc",
      // show: false,F
      isOpen: false,
      ticketId: "",
    };
  }

  sortData = () => {
    setTimeout(() => {
      this.state.sortDir === "asc"
        ? this.setState({ sortDir: "desc" })
        : this.setState({ sortDir: "asc" });
      this.findAllBooks(this.state.currentPage);
    }, 500);
  };

  componentDidMount() {
    this.findAllBooks(this.state.currentPage);
  }

  findAllBooks(currentPage) {
    currentPage -= 1;
    axios
      .get(
        GLOBALS.BASE_URL + "/companiestype?pageNumber=" +
          currentPage +
          "&pageSize=" +
          this.state.booksPerPage +
          "&sortBy=id&sortDir=" +
          this.state.sortDir,{
            headers: {
             
        "BCI-Key": GLOBALS.BCI_KEY
            }}
      )
      .then((response) => response.data)
      .then((data) => {
        this.setState({
          companiesType: data.content,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          currentPage: data.number + 1,
        });
      })
      .catch((error) => {
        console.log(error);
        localStorage.removeItem("jwtToken");
        this.props.history.push("/");
      });
  }

  deleteBook = (bookId) => {
    this.props.deleteCompanyType(bookId);
    setTimeout(() => {
      if (this.props.bookObject.companyType != null) {
        setTimeout(() => this.setState({ isOpen: false }), 1000);
        this.componentDidMount();
      } else {
        this.setState({ isOpen: false, });
      }
    }, 2000);
  };


  changePage = (event) => {
    let targetPage = parseInt(event.target.value);
    if (this.state.search) {
      this.searchData(targetPage);
    } else {
      this.findAllBooks(targetPage);
    }
    this.setState({
      [event.target.name]: targetPage,
    });
  };

  firstPage = () => {
    let firstPage = 1;
    if (this.state.currentPage > firstPage) {
      if (this.state.search) {
        this.searchData(firstPage);
      } else {
        this.findAllBooks(firstPage);
      }
    }
  };

  prevPage = () => {
    let prevPage = 1;
    if (this.state.currentPage > prevPage) {
      if (this.state.search) {
        this.searchData(this.state.currentPage - prevPage);
      } else {
        this.findAllBooks(this.state.currentPage - prevPage);
      }
    }
  };

  lastPage = () => {
    let condition = Math.ceil(
      this.state.totalElements / this.state.booksPerPage
    );
    if (this.state.currentPage < condition) {
      if (this.state.search) {
        this.searchData(condition);
      } else {
        this.findAllBooks(condition);
      }
    }
  };

  nextPage = () => {
    if (
      this.state.currentPage <
      Math.ceil(this.state.totalElements / this.state.booksPerPage)
    ) {
      if (this.state.search) {
        this.searchData(this.state.currentPage + 1);
      } else {
        this.findAllBooks(this.state.currentPage + 1);
      }
    }
  };

  searchChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  cancelSearch = () => {
    this.setState({ search: "" });
    this.findAllBooks(this.state.currentPage);
  };

  searchData = (currentPage) => {
    currentPage -= 1;
    axios
      .get(
        GLOBALS.BASE_URL + "/rest/books/search/" +
          this.state.search +
          "?page=" +
          currentPage +
          "&size=" +
          this.state.booksPerPage
      )
      .then((response) => response.data)
      .then((data) => {
        this.setState({
          books: data.content,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          currentPage: data.number + 1,
        });
      });
  };

  toggleModal = (itemId) => {
    this.setState({
      isOpen: !this.state.isOpen,
      ticketId: itemId
    });
  };


  render() {
    const { companiesType, currentPage, totalPages } = this.state;

    return (
      <div>
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  {/* <h1 className="m-0">Welcome </h1> */}
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">                    
                    <li className="breadcrumb-item">Home</li>
                    <li className="breadcrumb-item active">Company Type List</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <Modal show={this.state.isOpen} >
          <Modal.Body>
            <h3>Delete Data ?</h3>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={()=> this.deleteBook(this.state.ticketId)} >Delete</Button>
            <Button variant="secondary" onClick={()=> this.toggleModal()}>Cancel</Button>
          </Modal.Footer>
          </Modal>

          <div className="content">
            <div className="container-fluid">
              <Card className={"border"} >
                <Card.Header>
                  <div style={{ float: "left" }}>
                    <FontAwesomeIcon icon={faList} /> Company Type List
                  </div>
                  <div style={{ float: "right" }}>
                    {/* <InputGroup size="sm">
                      <FormControl
                        placeholder="Search"
                        name="search"
                        value={search}
                        className={"info-border bg-light text-white"}
                        onChange={this.searchChange}
                      />
                      <InputGroup.Append>
                        <Button
                          size="sm"
                          variant="outline-info"
                          type="button"
                          onClick={this.searchData}
                        >
                          <FontAwesomeIcon icon={faSearch} />
                        </Button>
                        <Button
                          size="sm"
                          variant="outline-danger"
                          type="button"
                          onClick={this.cancelSearch}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </Button>
                      </InputGroup.Append>
                    </InputGroup> */}
                  </div>
                </Card.Header>
                <Card.Body className="card-body table-responsive p-0">
                  <Table bordered hover striped variant="light">
                    <thead>
                      <tr>
                      <th>Code</th>
                        <th onClick={this.sortData}>
                        Name{" "}
                          <div
                            className={
                              this.state.sortDir === "asc"
                                ? "arrow arrow-up"
                                : "arrow arrow-down"
                            }
                          >
                            {" "}
                          </div>
                        </th>
                        <th>Created Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {companiesType.length === 0 ? (
                        <tr align="center">
                          <td colSpan="7">No Company Type Available.</td>
                        </tr>
                      ) : (
                        companiesType.map((companyType) => (
                          <tr key={companyType.id}>
                            {/* <td>
                              <Image
                                src={document.coverPhotoURL}
                                roundedCircle
                                width="25"
                                height="25"
                              />{" "}
                              {document.title}
                            </td> */}
                            <td>{companyType.id}</td>
                            <td>{companyType.companyTypeName}</td>
                            <td>{companyType.createdDate}</td>                                    
                    
                            <td>
                            <ButtonGroup>
                              <Link to={"companyType-edit/" + companyType.id}>
                                <Button 
                                    className="btn btn-outline-info"
                                    variant="outline-info" 
                                    size="sm">
                                    EDIT
                                </Button>
                                </Link>{' '}
                                <span>&nbsp;&nbsp;</span>
                                <Button
                                  size="sm"
                                  className="btn btn-outline-danger"
                                  variant="outline-danger"
                                  onClick={()=>this.toggleModal(companyType.id)}>
                                  DELETE
                                </Button>
                              </ButtonGroup>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </Card.Body>
                {companiesType.length > 0 ? (
                  <Card.Footer>
                    <div style={{ float: "left" }}>
                      Showing Page {currentPage} of {totalPages}
                    </div>
                    <div style={{ float: "right" }}>
                      <InputGroup size="sm">
                        <InputGroup.Prepend>
                          <Button
                            type="button"
                            variant="outline-info"
                            disabled={currentPage === 1 ? true : false}
                            onClick={this.firstPage}
                          >
                            <FontAwesomeIcon icon={faFastBackward} /> First
                          </Button>
                          <Button
                            type="button"
                            variant="outline-info"
                            disabled={currentPage === 1 ? true : false}
                            onClick={this.prevPage}
                          >
                            <FontAwesomeIcon icon={faStepBackward} /> Prev
                          </Button>
                        </InputGroup.Prepend>
                        <FormControl
                          className={"page-num bg-light"}
                          name="currentPage"
                          value={currentPage}
                          onChange={this.changePage}
                        />
                        <InputGroup.Append>
                          <Button
                            type="button"
                            variant="outline-info"
                            disabled={currentPage === totalPages ? true : false}
                            onClick={this.nextPage}
                          >
                            <FontAwesomeIcon icon={faStepForward} /> Next
                          </Button>
                          <Button
                            type="button"
                            variant="outline-info"
                            disabled={currentPage === totalPages ? true : false}
                            onClick={this.lastPage}
                          >
                            <FontAwesomeIcon icon={faFastForward} /> Last
                          </Button>
                        </InputGroup.Append>
                      </InputGroup>
                    </div>
                  </Card.Footer>
                ) : null}
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bookObject: state.companyType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteCompanyType: (bookId) => dispatch(deleteCompanyType(bookId)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyTypeList));
