import * as BT from "./documentTypes";
import axios from "axios";
import GLOBALS from '../../utils/Globals';

export const saveDocument = (document) => {
  return (dispatch) => {
    dispatch({
      type: BT.SAVE_DOCUMENT_REQUEST
    });
    axios
      .post(GLOBALS.BASE_URL + "/document/register", document,{headers: {
        "BCI-Key": GLOBALS.BCI_KEY
      }})
      .then((response) => {
        dispatch(documentSuccess(response.data));
        localStorage.setItem("jwtToken", response.data.token);
      })
      .catch((error) => {
        dispatch(documentFailure(error));
      });
  };
};

export const fetchDocument = (documentId) => {
  return (dispatch) => {
    dispatch({
      type: BT.FETCH_DOCUMENT_REQUEST,
    });
    axios
      .get(GLOBALS.BASE_URL + "/document-detail/id/?documentId=" + documentId,{headers: {
        "BCI-Key": GLOBALS.BCI_KEY
      }})
      .then((response) => {
        dispatch(documentSuccess(response.data.result));
        localStorage.setItem("jwtToken", response.data.token);
      })
      .catch((error) => {
        dispatch(documentFailure(error));
      });
  };
};

export const deleteDocument = (documentId) => {
  return (dispatch) => {
    dispatch({
      type: BT.UPDATE_DOCUMENT_REQUEST,
    });
    axios
      .delete(GLOBALS.BASE_URL + "/document/delete/" + documentId)
      .then((response) => {
        dispatch(documentSuccess(response.data));
        localStorage.setItem("jwtToken", response.data.token);
      })
      .catch((error) => {
        dispatch(documentFailure(error));
      });
  };
};

export const verifyDocument = (verifyDocument) => {
  return (dispatch) => {
    dispatch({
      type: BT.VERIFY_DOCUMENT_REQUEST,
    });
    axios
    .post(GLOBALS.BASE_URL + "/document/verify", verifyDocument, {headers: {
        "BCI-Key": GLOBALS.BCI_KEY
      }})
      .then((response) => {
        dispatch(documentSuccess(response.data));
        localStorage.setItem("jwtToken", response.data.token);
      })
      .catch((error) => {
        dispatch(documentFailure(error));
      });
  };
  
};

export const rejectDocument = (rejectDocument) => {
  return (dispatch) => {
    dispatch({
      type: BT.REJECT_DOCUMENT_REQUEST,
    });
    axios
    .post(GLOBALS.BASE_URL + "/document/reject", rejectDocument, {headers: {
        "BCI-Key": GLOBALS.BCI_KEY
      }})
      .then((response) => {
        dispatch(documentSuccess(response.data));
        localStorage.setItem("jwtToken", response.data.token);
      })
      .catch((error) => {
        dispatch(documentFailure(error));
      });
  };
  
};


export const changeStatus = (docStatusRequest) => {
  return (dispatch) => {
    dispatch({
      type: BT.DELETE_DOCUMENT_REQUEST,
    });
    axios
      .post(GLOBALS.BASE_URL + "/document/change-status", docStatusRequest)
      .then((response) => {
        dispatch(documentSuccess(response.data));
        localStorage.setItem("jwtToken", response.data.token);
      })
      .catch((error) => {
        dispatch(documentFailure(error));
      });
  };
};

export const assignTicket = (assignRequest) => {
  return (dispatch) => {
    dispatch({
      type: BT.UPDATE_DOCUMENT_REQUEST,
    });
    const headers = {
        "X-Reference-No": "web-assignTicket-",
        "BCI-Key": GLOBALS.BCI_KEY ,  
      };
      axios.post(GLOBALS.BASE_URL + "/document/assign-ticket?documentId="+assignRequest.documentId+"&email="+ assignRequest.email, null, {
        headers: headers
      })
      .then((response) => {
        dispatch(documentSuccess(response.data));
        localStorage.setItem("jwtToken", response.data.token);
      })
      .catch((error) => {
        dispatch(documentFailure(error));
      });
  };
};

// export const fetchDocumentById = (documentId) => {
//   return (dispatch) => {
//     dispatch({
//       type: BT.FETCH_DOCUMENT_REQUEST,
//     });
//     axios
//       .get(GLOBALS.BASE_URL + "/documents/?documentId=" + documentId,{headers: {
//         "BCI-Key": GLOBALS.BCI_KEY
//       }})
//       .then((response) => {
//         dispatch(documentSuccess(response.data.result));
//       })
//       .catch((error) => {
//         dispatch(documentFailure(error));
//       });
//   };
// };

export const verifyDocumentDetail = (verifyDocumentDetail) => {
  return (dispatch) => {
    dispatch({
      type: BT.VERIFY_DOCUMENT_REQUEST,
    });
    axios
    .post(GLOBALS.BASE_URL + "/document-detail/verify?docDetailId=" + verifyDocumentDetail.split("||")[0] + "&docDetailStatus="+verifyDocumentDetail.split("||")[1])
      .then((response) => {
        dispatch(documentSuccess(response.data));
        localStorage.setItem("jwtToken", response.data.token);
      })
      .catch((error) => {
        dispatch(documentFailure(error));
      });
  };
  
};

const documentSuccess = (document) => {
  return {
    type: BT.DOCUMENT_SUCCESS,
    payload: document,
  };
};

const documentFailure = (error) => {
  return {
    type: BT.DOCUMENT_FAILURE,
    payload: error,
  };
};
