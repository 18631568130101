import * as BT from "./companyTypes";
import axios from "axios";
import GLOBALS from '../../utils/Globals';

export const fetchCompany = (companyId) => {
  return (dispatch) => {
    dispatch({
      type: BT.FETCH_COMPANY_REQUEST,
    });
    axios
      .get(GLOBALS.BASE_URL + "/companies/" + companyId,{headers: {
        "BCI-Key": GLOBALS.BCI_KEY
      }})
      .then((response) => {
        dispatch(companySuccess(response.data));
        localStorage.setItem("jwtToken", response.data.token);
      })
      .catch((error) => {
        dispatch(companyFailure(error));
      });
  };
};

export const saveCompany = (user) => {
  return (dispatch) => {
    dispatch({
      type: BT.SAVE_COMPANY_REQUEST,
    });
    axios
      .post(GLOBALS.BASE_URL + "/company/register", user,{headers: {
        "BCI-Key": GLOBALS.BCI_KEY
      }})
      .then((response) => {
        dispatch(companySuccess(response.data));
        localStorage.setItem("jwtToken", response.data.token);
      })
      .catch((error) => {
        dispatch(companyFailure(error));
      });
  };
};

export const deleteCompany = (companyId) => {
  return (dispatch) => {
    dispatch({
      type: BT.DELETE_COMPANY_REQUEST,
    });
    axios
      .delete(GLOBALS.BASE_URL + "/company/delete/" + companyId)
      .then((response) => {
        dispatch(companyDeleteSuccess(response.data));
        localStorage.setItem("jwtToken", response.data.token);
      })
      .catch((error) => {
        dispatch(companyFailure(error));
      });
  };
};

const companySuccess = (company) => {
  return {
    type: BT.COMPANY_SUCCESS,
    payload: company,
  };
};

const companyFailure = (error) => {
  return {
    type: BT.COMPANY_FAILURE,
    payload: error,
  };
};

const companyDeleteSuccess = (company) => {
  return {
    type: BT.COMPANY_DELETE,
    payload: company,
  };
};
