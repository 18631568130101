import { combineReducers } from "redux";
import userReducer from "./user/userReducer";
import authReducer from "./user/auth/authReducer";
// import bookReducer from "./company/bookReducer";
import companyReducer from "./company/companyReducer";
import documentReducer from "./document/documentReducer";
import roleReducer from "./role/roleReducer";
import companyTypeReducer from "./companyType/companyTypeReducer";
// import { FormControl } from "react-bootstrap";

const rootReducer = combineReducers({
  user: userReducer,
  // book: bookReducer,
  auth: authReducer,
  company: companyReducer,
  document: documentReducer,
  role: roleReducer,
  companyType:companyTypeReducer,
});

export default rootReducer;
