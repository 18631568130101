const Global = {
    // BCI_KEY: 'a2lz-bWFu-IGlt-YW4g-dX-Ro-YSBh-bnRo-b255',
    // BASE_URL: 'http://localhost:8098',
    BCI_KEY: 'J5TBOfNBcO+zI+5T54KnG0ExliPVIpSNT806IcNh5+4=',
    BASE_URL: 'https://dpn.bankcapital.co.id/bci-dapen',
    COLOR: {
      BG_HEADER: '#8C0000',
      BG_SIDEHAR: '#112031',
    },
  };

  export default Global;