import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteDocument, changeStatus } from "../../services/index";
import "./../../assets/css/Style.css";
import {
    Card,
    Table,
    //ButtonGroup,
    Button,
    InputGroup,
    FormControl,
    Modal,
    Form,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faList,
    // faEdit,
    // faTrash,
    faStepBackward,
    faFastBackward,
    faStepForward,
    faFastForward,
    faSearch,
    // faSearch,
    // faTimes,
} from "@fortawesome/free-solid-svg-icons";
import MyToast from "../../utils/MyToast";
import axios from "axios";
import { Link } from "react-router-dom";
import GLOBALS from '../../utils/Globals';
import { withRouter } from "react-router";
import Moment from 'moment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

class ReportTicket extends Component {

    constructor(props) {
        super(props);
        this.state = {
            documents: [],
            documentsForExcel: [],
            search: "",
            currentPage: 1,
            documentPerPage: 10,
            sortDir: "asc",
            textReason: "",
            setStatus: "",
            assignParam: "",
            filName: "",
            filTicketNo: "",
            filNik: "",
            filStatus: "",
            filDate1: "",
            filDate2: "",
            // statusFinish: "finish",
            // statusFailed: "failed",
            arrStatus: [{ id: 'pending', name: 'Pending' },
            { id: 'verify_front', name: 'Verify Front' },
            { id: 'ready_to_los', name: 'Ready to LOS' },
            { id: 'finish', name: 'Finish' },
            { id: 'reject', name: 'Reject' },
            { id: 'failed', name: 'Failed' },
            { id: 'los_done', name: 'LOS Done' }],
            //userRole: localStorage.role,
        };
    }



    sortData = () => {
        setTimeout(() => {
            this.state.sortDir === "asc"
                ? this.setState({ sortDir: "desc" })
                : this.setState({ sortDir: "asc" });
            this.findAllDocument(this.state.currentPage);
        }, 500);
    };

    componentDidMount() {
        this.findAllDocument(this.state.currentPage);
    }
    

    findAllDocument(currentPage) {
        currentPage -= 1;
        var filStatus = this.state.filStatus;
        var startDate = this.state.filDate1;
        var endDate = this.state.filDate2;

        if (filStatus === '') {
            filStatus = 'all';
        }

        if (startDate === '') {
            startDate = new Date('2000-01-01');
        }

        if (endDate === '') {
            endDate = new Date();
        } else {
            if (startDate !== '' && endDate !== '') {
                var date1 = new Date(startDate);
                var date2 = new Date(endDate);
                date1.setHours(0, 0, 0, 0)
                date2.setHours(0, 0, 0, 0)
                if (date2 < date1) {
                    return alert('Tanggal akhir lebih kecil daripada tanggal awal, tanggal besar harus lebih besar daripada tanggal awal');
                }
            }
        }
        startDate = Moment(startDate).format('YYYY-MM-DD');
        endDate = Moment(endDate).format('YYYY-MM-DD');

        axios
            .get(
                GLOBALS.BASE_URL + "/documents/findAllFilter?pageNumber=" +
                currentPage +
                "&pageSize=" +
                this.state.documentPerPage +
                "&sortBy=id&sortDir=" +
                this.state.sortDir +
                "&status=" + filStatus +
                "&filTicketNo=" + this.state.filTicketNo +
                "&filName=" + this.state.filName +
                "&filNik=" + this.state.filNik +
                "&filStartDate=" + startDate +
                "&filEndDate=" + endDate +
                "&findType=reportTicket"
                , {
                    headers: {
                        "BCI-Key": GLOBALS.BCI_KEY
                    }
                }
            )
            .then((response) => response.data)
            .then((data) => {
                this.setState({
                    documents: data.result.content,
                    totalPages: data.result.totalPages,
                    totalElements: data.result.totalElements,
                    currentPage: data.result.number + 1,
                    documentsForExcel: data.result2
                });
                localStorage.setItem("jwtToken", data.token);
            })
            .catch((error) => {
                console.log(error);
                localStorage.removeItem("jwtToken");
                this.props.history.push("/login");
            });

    }

    deleteDocument = (documentId) => {
        this.props.deleteDocument(documentId);
        setTimeout(() => {
            if (this.props.bookObject != null) {
                this.setState({ show: true });
                setTimeout(() => this.setState({ show: false }), 3000);
                this.findAllDocument(this.state.currentPage);
            } else {
                this.setState({ show: false });
            }
        }, 1000);
    };

    changePage = (event) => {
        let targetPage = parseInt(event.target.value);
        if (this.state.search) {
            this.searchData(targetPage);
        } else {
            this.findAllDocument(targetPage);
        }
        this.setState({
            [event.target.name]: targetPage,
        });
    };

    firstPage = () => {
        let firstPage = 1;
        if (this.state.currentPage > firstPage) {
            if (this.state.search) {
                this.searchData(firstPage);
            } else {
                this.findAllDocument(firstPage);
            }
        }
    };

    prevPage = () => {
        let prevPage = 1;
        if (this.state.currentPage > prevPage) {
            if (this.state.search) {
                this.searchData(this.state.currentPage - prevPage);
            } else {
                this.findAllDocument(this.state.currentPage - prevPage);
            }
        }
    };

    lastPage = () => {
        let condition = Math.ceil(
            this.state.totalElements / this.state.documentPerPage
        );
        if (this.state.currentPage < condition) {
            if (this.state.search) {
                this.searchData(condition);
            } else {
                this.findAllDocument(condition);
            }
        }
    };

    nextPage = () => {
        if (
            this.state.currentPage <
            Math.ceil(this.state.totalElements / this.state.documentPerPage)
        ) {
            if (this.state.search) {
                this.searchData(this.state.currentPage + 1);
            } else {
                this.findAllDocument(this.state.currentPage + 1);
            }
        }
    };

    searchChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    cancelSearch = () => {
        this.setState({ search: "" });
        this.findAllDocument(this.state.currentPage);
    };

    searchData = (currentPage) => {
        currentPage -= 1;
        axios
            .get(
                "http://localhost:8081/rest/books/search/" +
                this.state.search +
                "?page=" +
                currentPage +
                "&size=" +
                this.state.documentPerPage
            )
            .then((response) => response.data)
            .then((data) => {
                this.setState({
                    books: data.result.content,
                    totalPages: data.totalPages,
                    totalElements: data.totalElements,
                    currentPage: data.number + 1,
                });
            });
    };

    state = {
        isOpen: false
    };

    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    openModalAsign = (itemAssign) => this.setState({ isOpenAssign: true, assignParam: itemAssign });
    closeModalAsign = () => this.setState({ isOpenAssign: false });

    toggleModal = (itemId) => {
        this.setState({
            isOpen: !this.state.isOpen,
            setStatus: itemId
        });
    };

    changeStatus = (docParams) => {
        const docStatusRequest = {
            documentId: docParams.split("||")[0],
            status: docParams.split("||")[1],
            reason: this.state.textReason,
        };

        this.props.changeStatus(docStatusRequest);
        setTimeout(() => {
            if (this.props.bookObject.document != null) {
                this.setState({ show: true });
                setTimeout(() => this.setState({ show: false }), 1000);
                setTimeout(() => {
                    return this.props.history.push("/home");
                }, 2000);
                // this.findAllDocument(this.state.currentPage);
            } else {
                this.setState({ show: false });
            }
        }, 1000);
        this.toggleModal();
        this.setState({ show: true });
    };

    // assignTicket = (par) => {

    //   this.props.assignTicket(par);
    //   setTimeout(() => {
    //     if (this.props.bookObject.document != null) {
    //       this.props.history.push("/document-verify/"+par);
    //       // to={"document-verify/" + document.id}>
    //     }
    //   }, 1000);
    // };


    render() {
        const { documents, currentPage, totalPages, arrStatus, filTicketNo, filName, filNik, filStatus, filDate1, filDate2, totalElements, documentsForExcel } = this.state;
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                this.findAllDocument(1)
            }
        }

        const ExportCSV = ({csvData, fileName}) => {

            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
        
            const exportToCSV = (csvData, fileName) => {
                const csvDataModel = csvData.map(elem => (
                    {
                        ticketNo: elem.ticketNo,
                        name: elem.name,
                        nik: elem.nik,
                        npwp: elem.npwp,                      
                        noSkep: elem.noSkep,
                        status: elem.status,
                        type: elem.type,
                        notes: elem.notes,
                        createdDate: elem.createdDate,
                        accountOfficer: elem.accountOfficer,
                    } 
                  ));


                const ws = XLSX.utils.json_to_sheet(csvDataModel);
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], {type: fileType});
                FileSaver.saveAs(data, fileName + fileExtension);
            }
        
            return (
                <Button variant="warning" onClick={(e) => exportToCSV(csvData,fileName)}>Export to Excel</Button>
            )
        }

        function b(idToSearch) {
            return arrStatus.filter(item => {
                return item.id === idToSearch
            }).map(e => e.name)
        };

        if (typeof documents === 'undefined') {
            return (
                documents: 0
            )
        }

        if (typeof documentsForExcel === 'undefined') {
            return (
                documentsForExcel: 0
            )
        }

        return (
            <div>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    {/* <h1 className="m-0">Welcome </h1> */}
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item">Home</li>
                                        <li className="breadcrumb-item active">List Verifying Documents</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal show={this.state.isOpen} size="lg" onHide={this.closeModal}>
                        <Modal.Header closeButton>
                            <Button variant="light" disabled></Button>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group className="mb-3" controlId="reason">
                                <Form.Label>Notes : </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    value={this.state.textReason}
                                    onChange={e => this.setState({ textReason: e.target.value })}
                                    rows={3}
                                />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={() => this.changeStatus(this.state.setStatus)}>Submit</Button>
                            <Button variant="secondary" onClick={() => this.toggleModal()}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.isOpenAssign}  >
                        <Modal.Body>
                            <h3>Take this ticket ?</h3>
                        </Modal.Body>
                        <Modal.Footer>
                            {/* <Button variant="success" onClick={()=> this.assignTicket(this.state.assignParam)}>Yes</Button> */}
                            <Link
                                to={"document-verify/" + this.state.assignParam}>
                                <Button
                                    variant="success"
                                >Yes</Button>
                            </Link>
                            <Button variant="secondary" onClick={() => this.closeModalAsign()}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>

                    <div className="content">
                        <div className="container-fluid">
                            <div style={{ display: this.state.show ? "block" : "none" }}>
                                <MyToast
                                    show={this.state.show}
                                    message={"Status Update Successfully."}
                                    type={"success"}
                                />
                            </div>
                            <Card className={"filter"}>
                                <Card.Header>
                                    <div style={{ float: "left" }}>
                                        <FontAwesomeIcon icon={faList} /> Filter
                  </div>
                                </Card.Header>
                                <Card.Body className="card-body ">
                                    <div className="form-group row">
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label htmlFor="filTicketNo" className="col-form-label">Ticket No</label>
                                                <input type="text" className="form-control" name="filTicketNo" placeholder="Enter Ticket No" value={filTicketNo} onChange={this.searchChange} onKeyPress={handleKeyPress} />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label htmlFor="filName" className="col-form-label">Name</label>
                                                <input type="text" className="form-control" name="filName" placeholder="Enter Name" value={filName} onChange={this.searchChange} onKeyPress={handleKeyPress} />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label htmlFor="filNik" className="col-form-label">NIK</label>
                                                <input type="text" className="form-control" name="filNik" placeholder="Enter NIK" value={filNik} onChange={this.searchChange} onKeyPress={handleKeyPress} />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label htmlFor="filStatus" className="col-form-label">Status</label>
                                                {/* <input type="text" className="form-control" name="filStatus" placeholder="Enter Status" onChange={this.searchChange} value={filStatus} /> */}
                                                <select onChange={this.searchChange} name="filStatus" value={filStatus} className="form-control" >
                                                    <option value="all">All Status</option>
                                                    <option value="pending">Pending</option>
                                                    <option value="verify_front">Verify Front</option>
                                                    <option value="ready_to_los">Ready to LOS</option>
                                                    <option value="reject">Reject</option>
                                                    <option value="finish">Finish</option>
                                                    <option value="failed">Failed</option>
                                                    <option value="los_done">LOS Done</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label htmlFor="filDate1" className="col-form-label">Created Date</label>
                                                <input type="date" className="form-control" name="filDate1" placeholder="Enter Date" onChange={this.searchChange} value={filDate1} onKeyPress={handleKeyPress} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="filDate2" className="col-form-label">Until</label>
                                                <input type="date" className="form-control" name="filDate2" placeholder="Enter Date" onChange={this.searchChange} value={filDate2} onKeyPress={handleKeyPress} />
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                                <Card.Footer>
                                    <center>
                                        <button size="md" className="btn btn-info" name="btnFilter" onClick={() => this.findAllDocument(1)}>
                                            <FontAwesomeIcon icon={faSearch} /> Search
                                        </button> &nbsp;
                                        <ExportCSV csvData={this.state.documentsForExcel} fileName="Report Ticket" />
                                    </center>
                                </Card.Footer>
                            </Card>

                            <Card className={"border"}>
                                <Card.Header>
                                    <div style={{ float: "left" }}>
                                        <FontAwesomeIcon icon={faList} /> List Verifying Documents
                  </div>
                                    {/* <div style={{ float: "right" }}>
                    <InputGroup size="sm">
                      <FormControl
                        placeholder="Search"
                        name="search"
                        value={search}
                        className={"info-border bg-light text-white"}
                        onChange={this.searchChange}
                      />
                      <InputGroup.Append>
                        <Button
                          size="sm"
                          variant="outline-info"
                          type="button"
                          onClick={this.searchData}
                        >
                          <FontAwesomeIcon icon={faSearch} />
                        </Button>
                        <Button
                          size="sm"
                          variant="outline-danger"
                          type="button"
                          onClick={this.cancelSearch}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </div> */}
                                </Card.Header>
                                <Card.Body className="card-body table-responsive p-0">
                                    <Table bordered hover striped variant="light">
                                        <thead>
                                            <tr>
                                                <th>Ticket No</th>
                                                <th>Name</th>
                                                <th>NIK</th>
                                                <th>NPWP</th>
                                                <th>No SKEP</th>
                                                <th>Status</th>
                                                <th>Doc Type</th>
                                                <th>Notes</th>
                                                <th>Created Date</th>
                                                <th>Account Officer</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {documents.length === 0 ? (
                                                <tr align="center">
                                                    <td colSpan="8">No Document Available.</td>
                                                </tr>
                                            ) : (
                                                    documents.map((document) => (
                                                        <tr key={document.id}>
                                                            <td>{document.ticketNo}</td>
                                                            <td>{document.name}</td>
                                                            <td>{document.nik}</td>
                                                            <td>{document.npwp}</td>
                                                            <td>{document.noSkep}</td>
                                                            <td>{b(document.status)}</td>
                                                            <td>{document.type}</td>
                                                            <td>{document.notes}</td>
                                                            <td>{document.createdDate}</td>
                                                            <td>{document.accountOfficer}</td>
                                                        </tr>
                                                    ))
                                                )}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                {documents.length > 0 ? (
                                    <Card.Footer>
                                        <div style={{ float: "left" }}>
                                            Showing Page {currentPage} of {totalPages} (Total Data : {totalElements} data)
                    </div>
                                        <div style={{ float: "right" }}>
                                            <InputGroup size="sm">
                                                <InputGroup.Prepend>
                                                    <Button
                                                        type="button"
                                                        variant="outline-info"
                                                        disabled={currentPage === 1 ? true : false}
                                                        onClick={this.firstPage}
                                                    >
                                                        <FontAwesomeIcon icon={faFastBackward} /> First
                          </Button>
                                                    <Button
                                                        type="button"
                                                        variant="outline-info"
                                                        disabled={currentPage === 1 ? true : false}
                                                        onClick={this.prevPage}
                                                    >
                                                        <FontAwesomeIcon icon={faStepBackward} /> Prev
                          </Button>
                                                </InputGroup.Prepend>
                                                <FormControl
                                                    className={"page-num bg-light"}
                                                    name="currentPage"
                                                    value={currentPage}
                                                    onChange={this.changePage}
                                                />
                                                <InputGroup.Append>
                                                    <Button
                                                        type="button"
                                                        variant="outline-info"
                                                        disabled={currentPage === totalPages ? true : false}
                                                        onClick={this.nextPage}
                                                    >
                                                        <FontAwesomeIcon icon={faStepForward} /> Next
                          </Button>
                                                    <Button
                                                        type="button"
                                                        variant="outline-info"
                                                        disabled={currentPage === totalPages ? true : false}
                                                        onClick={this.lastPage}
                                                    >
                                                        <FontAwesomeIcon icon={faFastForward} /> Last
                          </Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </div>
                                    </Card.Footer>
                                ) : null}
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        bookObject: state.document,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteDocument: (documentId) => dispatch(deleteDocument(documentId)),
        changeStatus: (documentId) => dispatch(changeStatus(documentId)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportTicket));
