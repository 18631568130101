import React, { Component } from "react";

import { connect } from "react-redux";
import {
  saveDocument,
  fetchDocument,
  verifyDocument,
  rejectDocument,
  verifyDocumentDetail,
  assignTicket,
} from "../../services/index";

import { 
    Card,
    ListGroup, 
    Button,
    Col,
    Row,
    Table,
    Modal,
    ProgressBar,
    Form,
} from "react-bootstrap";

import "../../App.css";
import axios from "axios";
import { Buffer } from "buffer"
import ReactiveButton from 'reactive-button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import GLOBALS from '../../utils/Globals';
import { withRouter } from "react-router";

class DocumentVerify extends Component {
    constructor(props) {
        super(props);
        this.state = this.initialState;        
    }

    initialState = {
        documentDetails: [],
        docs: [],
        fileName: "",
        binary_data: "",
        ticketId: "",
        ticketNo: "",
        agentName: "",
        loadingReject: "idle",
        loadingVerify: "idle",
        isOpen: false,
        varr: "",
        rejectReason: "",
        isOpenReject: false,
        isOpenVerify: false,
        fotoLocation: "",
        docDetailId: "",
        docDetailStatus: "",
        isOpenNoDocs: false,
        verifyPkStatus: "0",
        verifyFotoNasabah: "0",
        errorDesc: "",
        docsOwner: "",
    };

    componentDidMount() {
    const documentId = +this.props.match.params.id;
    if (documentId) {
        this.findDocumentById(documentId);
        this.findDocumenDetailtById(documentId);
        }
    }

    findDocumentById(documentId) {
        axios
          .get(GLOBALS.BASE_URL + "/documents/" + documentId,{headers: {
              "BCI-Key": GLOBALS.BCI_KEY
            }
          }
          )
          .then((response) => {
            this.setState({
              
              docs: response.data.result,
              docsOwner: response.data.result.accountOfficer,
              ticketNo: response.data.result.ticketNo,
              agentName: response.data.result.createdByName,
            });
            localStorage.setItem("jwtToken", response.data.token);
          })
          .catch((error) => {
            console.log(error);
            localStorage.removeItem("jwtToken");
            this.props.history.push("/login");
          });
      }

    openModal (item) {
        this.setState({ 
            isOpen: true,
            modalData: item.type + "/" + item.fileName,
            fotoLocation: item.location,
            docDetailId: item.id + "||" + item.type
        })

        if(item.fileName !== ''){
            this.getImage(item.type + "/" + item.fileName)
        }
    }

    openModalReject (item) {
        this.setState({ 
            isOpenReject: true,
            modalData: item 
        })
    }

    openModalVerify (item) {

        this.props.fetchDocument(item);
        let document = this.props.bookObject.document;   
        if(typeof document !=='undefined'){
            if (document.length != null) {
            this.setState({
                documentDetails: document,
                ticketId: document[0].documentId,
            });
            }
        }

        var docsDetails = this.state.documentDetails;
        var i;
        var flagDetail = true;
        if(localStorage.role === 'ROLE_FRONTING_VERIFIER'){
            for(i=0; i < docsDetails.length; i++){
                if(docsDetails[i].status === 'verify_ao' || docsDetails[i].status === 'verify_front'){
                    flagDetail = true;
                }else{
                    this.setState({isOpenNoDocs: true, errorDesc: "please verify all available document details"});
                    flagDetail = false;
                    break;
                }
            }
        }else{
            for(i=0; i < docsDetails.length; i++){
                if(docsDetails[i].status === 'verify_ao'){
                    flagDetail = true;
                }else{
                    this.setState({isOpenNoDocs: true, errorDesc: "please verify all available document details"});
                    flagDetail = false;
                    break;
                }
            }
        }

        if(flagDetail !== false){
            this.setState({ 
                isOpenVerify: true,
                isOpenNoDocs: false,
                modalData: item
            })
        }
        
    }

    closeModal = () => this.setState({ isOpen: false });
    closeModalReject = () => this.setState({ isOpenReject: false });
    closeModalVerify = () => this.setState({ isOpenVerify: false });

    findDocumenDetailtById = (documentId) => {
        this.props.fetchDocument(documentId);
        setTimeout(() => {
            let document = this.props.bookObject.document;
            
            if(typeof document ==='undefined'){
                this.setState({isOpenNoDocs: true, errorDesc: "Document Details Not Available"});
            }else{

                if(localStorage.role === 'ROLE_BCI_AO'){
                    //check ticket owner
                    if(this.state.docsOwner === null || this.state.docsOwner === localStorage.email.replace(/['"]+/g, '')){
                        //assign ticket
                        const assignRequest = {
                            documentId: this.state.docs.id,
                            email: localStorage.email.replace(/['"]+/g, ''),
                          };
                        this.props.assignTicket(assignRequest);
                    }else{
                        this.setState({isOpenNoDocs: true, errorDesc: "this ticket already taken by "+this.state.docsOwner});
                    }
                }
                
                if (document.length != null) {
                this.setState({
                    documentDetails: document,
                    ticketId: document[0].documentId,
                });
                }
            }
        }, 1000);
    };

    toggleModal = (errorDesc) => {
        if(errorDesc.indexOf('Not') !== -1 || errorDesc.indexOf('taken') !== -1){
            this.props.history.push("/document-list");
        }else{
            this.setState({isOpenNoDocs: false});
            this.componentDidMount();
        }
        
    };

    verifyDocumenDetailtId = (ticketId) => {
        const verifyRequest = {
            documentId: this.state.ticketId,
            reason: this.state.rejectReason,
          };
          
        this.props.verifyDocument(verifyRequest);
        this.setState({ loadingVerify: "loading", isOpenVerify: false });
        setTimeout(() => {
          if (this.props.bookObject.document != null) {
            setTimeout(() => this.setState({ isOpen: false, loadingVerify: "success", }), 1000);
            // this.componentDidMount();
            setTimeout(() => {
                return this.props.history.push("/document-list");
              }, 2000);
          } else {
            this.setState({ isOpen: false, });
          }
        }, 3000);
      };

    getImage = (fileName) => {
        axios({
            method: 'get',
            url: GLOBALS.BASE_URL + '/upload-document/download?file='+fileName,
            headers: { 
                'BCI-Key': GLOBALS.BCI_KEY, 
                'Authorization': localStorage.jwtToken,
                'Content-type': 'application/octet-stream',
            },
            responseType: 'arraybuffer'
          })
            .then((response) => response.data)
            .then((response) => {
                let base64ImageString = Buffer.from(response, 'binary').toString('base64');

                this.setState({
                    binary_data: base64ImageString,
                    location: fileName,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    verifyDocumentId = (ticketId) => {
        const verifyRequest = {
            documentId: this.state.ticketId,
            reason: this.state.rejectReason,
            verifyPkStatus: this.state.verifyPkStatus,
            verifyFotoNasabah: this.state.verifyFotoNasabah,
        };
        
        this.props.verifyDocument(verifyRequest);
        this.setState({ loadingVerify: "loading", isOpenVerify: false });
        setTimeout(() => {
        if (this.props.bookObject.document != null) {
            setTimeout(() => this.setState({ isOpen: false, loadingVerify: "success", }), 1000);
            setTimeout(() => {
                return this.props.history.push("/document-list");
            }, 2000);
        } else {
            this.setState({ isOpen: false, });
        }
        }, 3000);

    };

    rejectDocumentId = (ticketId) => {
        const rejectRequest = {
            documentId: this.state.ticketId,
            reason: this.state.rejectReason,
          };

        this.props.rejectDocument(rejectRequest);
        this.setState({ loadingReject: "loading", isOpenReject: false });
        setTimeout(() => {
            if(this.props.bookObject.document != null){
            setTimeout(() => {
                this.setState({ loadingReject: "error", });
            },1000);
            setTimeout(() => {
                return this.props.history.push("/document-list");
            }, 2000);
            }
        }, 3000);
    };

    printImg(img) {
        var srcImg = "data:image/png;base64," + img;
        var win = window.open("", "Image");
        win.document.write("<img src='" + srcImg + "''>");
        win.window.print();        
      }

    verifyDocumentDetailId = (docDetailId) => {
        var detailStatus = '';
        if(localStorage.role === 'ROLE_FRONTING_VERIFIER'){
            detailStatus = 'verify_front';
        }else{
            detailStatus = 'verify_ao';
        }
        
        this.props.verifyDocumentDetail(docDetailId.split("||")[0] + "||" + detailStatus);
        setTimeout(() => {
            if (this.props.bookObject.document != null) {

                // HERE
                if(docDetailId.split("||")[1] === "fotoNasabah"){
                    this.setState({verifyFotoNasabah: "1"});
                }
                if(docDetailId.split("||")[1] === "fotoTandatanganPK"){
                    this.setState({verifyPkStatus: "1"});
                }
                setTimeout(() => this.setState({ isOpen: false, }), 1000);
                this.componentDidMount();
            } else {
                this.setState({ isOpen: false, });
            }
        }, 2000);
    };
    
    render() {
        const { documentDetails, binary_data, ticketId, loadingReject, loadingVerify, docs, ticketNo, 
            agentName, errorDesc} = this.state;

        let varr= JSON.stringify(documentDetails);
        
        return (    
            <div>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                            </div>
                            <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">                    
                                <li className="breadcrumb-item">Home</li>
                                <li className="breadcrumb-item active">Document List</li>
                                <li className="breadcrumb-item active">Document Verify</li>
                            </ol>
                            </div>
                        </div>
                        </div>
                    </div>

                    <Modal show={this.state.isOpenNoDocs} >
                        <Modal.Body>
                            <h3>{errorDesc}</h3>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={()=> this.toggleModal(errorDesc)}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                    
                    <Modal show={this.state.isOpen} dialogClassName="full-screen-modal" onHide={this.closeModal}> 
                        <Modal.Header closeButton>
                            <Modal.Title>Verify Documents</Modal.Title>
                            <Button variant="light"  disabled></Button>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col className="col-image" xl={9}>
                                    <img src={`data:image/png;base64,${binary_data}`} fluid alt="Retrieve failed"/>
                                </Col>
                                <Col xl={3}>
                                <Card style={{ width: '18rem' }}>
                                    <Card.Body>
                                        <Card.Title>Location picture taken :</Card.Title>
                                        <Card.Text>
                                            {this.state.fotoLocation}
                                        </Card.Text>
                                    </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer
                            style={{
                            display: "flex",
                            justifyContent: "center",
                        }}>
                            <Button variant="light" mr-center onClick={() => this.printImg(binary_data)}>Print</Button>
                            <a href={`data:image/png;base64,${binary_data}`} download={this.state.modalData}>
                                <Button variant="info" mr-center>Download</Button>
                            </a>
                            <Button variant="success" mr-center onClick={() => this.verifyDocumentDetailId(this.state.docDetailId)}>Verify</Button>
                        </Modal.Footer>
                    </Modal>
                    
                    <Modal show={this.state.isOpenReject} size="lg" onHide={this.closeModalReject}>
                        <Modal.Header closeButton>
                            <Modal.Title>Reject Document</Modal.Title>
                            <Button variant="light"  disabled></Button>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group className="mb-3" controlId="reason">
                                <Form.Label>Rejection reason : </Form.Label>
                                <Form.Control 
                                    as="textarea" 
                                    value={this.state.rejectReason}
                                    onChange={e => this.setState({ rejectReason: e.target.value })}
                                    rows={3} 
                                />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer
                            style={{
                            display: "flex",
                            justifyContent: "center",
                        }}>                    
                            <Button variant="danger" onClick={() => this.rejectDocumentId(ticketId)}>
                                Reject
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.isOpenVerify} size="lg" onHide={this.closeModalVerify}> 
                        <Modal.Header closeButton>
                            <Modal.Title>Verify Document</Modal.Title>
                            <Button variant="light"  disabled></Button>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group className="mb-3" controlId="reason">
                                <Form.Label>Verify Notes : </Form.Label>
                                <Form.Control 
                                    as="textarea" 
                                    value={this.state.rejectReason}
                                    onChange={e => this.setState({ rejectReason: e.target.value })}
                                    rows={3} 
                                />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer
                            style={{
                            display: "flex",
                            justifyContent: "center",
                        }}>                    
                            <Button variant="success" onClick={() => this.verifyDocumentId(ticketId)}>
                                Verify
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <div className="content">
                    <div className="container-fluid">
                        <Card className={"border"}>
                            <Card.Header><b>Verifying documents for ticket no : {ticketNo}</b></Card.Header>
                            <Card.Body className="card-body table-responsive p-0">
                                <Table bordered hover striped variant="light">
                                    <tbody>
                                        <tr>
                                            <td>Agent Name</td>
                                            <td>{agentName}</td>
                                        </tr>
                                        <tr>
                                            <td>Ticket No</td>
                                            <td>{ticketNo}</td>
                                        </tr>
                                        <tr>
                                            <td>Nama</td>
                                            <td>{docs.name}</td>
                                        </tr>
                                        <tr>
                                            <td>NIK</td>
                                            <td>{docs.nik}</td>
                                        </tr>
                                        <tr>
                                            <td>Type</td>
                                            <td>{docs.type}</td>
                                        </tr>
                                        <tr>
                                            <td>Status</td>
                                            <td>{docs.status}</td>
                                        </tr>
                                        <tr class="solid"></tr>
                                        <tr>
                                            <td>NPWP</td>
                                            <td>{docs.npwp}</td>
                                        </tr>
                                        <tr>
                                            <td>SKEP</td>
                                            <td>{docs.noSkep}</td>
                                        </tr>
                                        <tr>
                                            <td>Province</td>
                                            <td>{docs.province}</td>
                                        </tr>
                                        <tr>
                                            <td>Regency</td>
                                            <td>{docs.kabupaten}</td>
                                        </tr>
                                        <tr>
                                            <td>District</td>
                                            <td>{docs.kecamatan}</td>
                                        </tr>
                                        <tr>
                                            <td>Village</td>
                                            <td>{docs.kelurahan}</td>
                                        </tr>
                                        <tr>
                                            <td>RT</td>
                                            <td>{docs.rt}</td>
                                        </tr>
                                        <tr>
                                            <td>RW</td>
                                            <td>{docs.rw}</td>
                                        </tr>
                                        <tr>
                                            <td>Post Code</td>
                                            <td>{docs.postCode}</td>
                                        </tr>
                                        <tr class="solid"></tr>
                                        <tr>
                                            <td>Province Domicile</td>
                                            <td>{docs.provinceDomisili}</td>
                                        </tr>
                                        <tr>
                                            <td>Regency Domicile</td>
                                            <td>{docs.kabupatenDomisili}</td>
                                        </tr>
                                        <tr>
                                            <td>District Domicile</td>
                                            <td>{docs.kecamatanDomisili}</td>
                                        </tr>
                                        <tr>
                                            <td>Village Domicile</td>
                                            <td>{docs.kelurahanDomisili}</td>
                                        </tr>
                                        <tr>
                                            <td>RT Domicile</td>
                                            <td>{docs.rtDomisili}</td>
                                        </tr>
                                        <tr>
                                            <td>RW Domicile</td>
                                            <td>{docs.rwDomisili}</td>
                                        </tr>
                                        <tr>
                                            <td>Post Code Domicile</td>
                                            <td>{docs.postCodeDomisili}</td>
                                        </tr>
                                        <tr>
                                            <td>Notes</td>
                                            <td>{docs.notes}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                        <Card className={"border"}>
                            <Card.Header><b>Document Details </b></Card.Header>
                            <Card.Body className="card-body table-responsive p-0">
                            <Table bordered hover striped variant="light">
                                <thead>
                                    <tr>
                                        <th>DOCUMENT TYPE</th>
                                        <th>DOCUMENT STATUS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {documentDetails.length === 0 ? (
                                        <tr align="center">
                                            <td colSpan="7">No Document Available.</td>
                                        </tr>
                                    ) : (
                                    <>
                                        <tr>  
                                        {varr.indexOf('skep') !== -1 ? (  
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'skep'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                                SKEP Pensiun
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                    SKEP Pensiun
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                        <tr>  
                                        {varr.indexOf('ktp') !== -1 ? (  
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'ktp'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                                KTP
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                        KTP
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                        <tr>  
                                        {varr.indexOf('ktpPasangan') !== -1 ? (  
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'ktpPasangan'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                                KTP Pasangan
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                    KTP Pasangan
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                        <tr>  
                                        {varr.indexOf('kartuKeluarga') !== -1 ? (  
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'kartuKeluarga'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                            Kartu Keluarga
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                    Kartu Keluarga
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                        <tr>  
                                        {varr.indexOf('suratNikahAtwAktaCerai') !== -1 ? (  
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'suratNikahAtwAktaCerai'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                            Surat Nikah / Akta Cerai
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                    Surat Nikah / Akta Cerai
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                        <tr>  
                                        {varr.indexOf('npwp') !== -1 ? (  
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'npwp'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                            NPWP
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                    NPWP
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                        <tr>  
                                        {varr.indexOf('manfaatPensiunTerakhir') !== -1 ? (  
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'manfaatPensiunTerakhir'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                            Informasi Manfaat Pensiun Terakhir
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                    Informasi Manfaat Pensiun Terakhir
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                        <tr>  
                                        {varr.indexOf('kartuIdentitasPensiun') !== -1 ? (  
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'kartuIdentitasPensiun'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                            Kartu Identitas Pensiun
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                    Kartu Identitas Pensiun
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                        <tr>  
                                        {varr.indexOf('fotoNasabah') !== -1 ? (  
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'fotoNasabah'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                            Foto Nasabah Berdiri
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                    Foto Nasabah Berdiri
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                        <tr>  
                                        {varr.indexOf('fotoSelfieDgnSales') !== -1 ? (  
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'fotoSelfieDgnSales'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                            Foto Selfie
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                    Foto Selfie
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                        <tr>  
                                        {varr.indexOf('buktiAngsuranTerakhir') !== -1 ? (  
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'buktiAngsuranTerakhir'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                            Bukti Angsuran Terakhir
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                    Bukti Angsuran Terakhir
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                        <tr>  
                                        {varr.indexOf('skepBanklain') !== -1 ? (  
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'skepBanklain'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                            Bukti Tanda Terima SKEP pada Bank Lain <br/>atau Surat Penyerta
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                    Bukti Tanda Terima SKEP pada Bank Lain <br/>atau Surat Penyerta
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                        <tr>  
                                        {varr.indexOf('tandaTerimaDokumenSkep') !== -1 ? (  
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'tandaTerimaDokumenSkep'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                            Tanda Terima Dokumen SKEP
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                    Tanda Terima Dokumen SKEP
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                        <tr>  
                                        {varr.indexOf('buktiKeterangaLunas') !== -1 ? (  
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'buktiKeterangaLunas'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                            Bukti Keterangan Lunas
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                    Bukti Keterangan Lunas
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                        <tr>  
                                        {varr.indexOf('formulirInformasiDataNasabah') !== -1 ? (  
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'formulirInformasiDataNasabah'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                            Formulir Informasi Data Nasabah
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                    Formulir Informasi Data Nasabah
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                        <tr>  
                                        {varr.indexOf('aplikasiPembukaanRekTabungan') !== -1 ? (  
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'aplikasiPembukaanRekTabungan'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                            Aplikasi Pembukaan Rekening Tabungan
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                    Aplikasi Pembukaan Rekening Tabungan
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                        <tr>  
                                        {varr.indexOf('formulirPermohonanKreditPensiun') !== -1 ? (  
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'formulirPermohonanKreditPensiun'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                            Formulir Permohonan Kredit Pensiun
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                    Formulir Permohonan Kredit Pensiun
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                        <tr>  
                                        {varr.indexOf('dokumenSuratPernyataanAngsuranGt70Persen') !== -1 ? (  
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'dokumenSuratPernyataanAngsuranGt70Persen'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                            Dokumen Surat Pernyataan Angsuran > 70%
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                    Dokumen Surat Pernyataan Angsuran > 70%
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                        <tr>  
                                        {varr.indexOf('sPPRekPembayaranManfaatPensiun') !== -1 ? (  
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'sPPRekPembayaranManfaatPensiun'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                            Surat Permohonan Pindah Rekening <br/> Pembayaran Manfaat Pensiun
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                    Surat Permohonan Pindah Rekening <br/> Pembayaran Manfaat Pensiun
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                        <tr>  
                                        {varr.indexOf('suratPernyataanFlaging') !== -1 ? (  
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'suratPernyataanFlaging'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                            Surat Pernyataan Flagging
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                    Surat Pernyataan Flagging
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                        <tr> 
                                        {varr.indexOf('suratPenyertaBedaTTD') !== -1 ? (  
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'suratPenyertaBedaTTD'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                            Surat Penyerta Beda Tanda Tangan
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                    Surat Penyerta Beda Tanda Tangan
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                        <tr>  
                                        {varr.indexOf('perjanjianKreditPensiun') !== -1 ? (  
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'perjanjianKreditPensiun'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                            Perjanjian Kredit Pensiun
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                    Perjanjian Kredit Pensiun
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                        <tr>  
                                        {varr.indexOf('suratKeteranganLunas') !== -1 ? (  
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'suratKeteranganLunas'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                            Surat Keterangan Lunas
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                    Surat Keterangan Lunas
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                        <tr>  
                                        {varr.indexOf('instruksiTransfer') !== -1 ? (  
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'instruksiTransfer'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                            Instruksi Transfer
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                    Instruksi Transfer
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                        <tr>
                                        {varr.indexOf('dokumenAsuransi') !== -1 ? (
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'dokumenAsuransi'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                            Dokumen Asuransi
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                    Dokumen Asuransi
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                        <tr>
                                        {varr.indexOf('suratKonfirmasiBank') !== -1 ? (
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'suratKonfirmasiBank'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                            Surat Konfirmasi Bank
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                    Surat Konfirmasi Bank
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                        <tr>
                                        {varr.indexOf('contohTandaTanganKCTT') !== -1 ? (
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'contohTandaTanganKCTT'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                            Contoh Tanda Tangan (KCTT)
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                    Contoh Tanda Tangan (KCTT)
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                        <tr>
                                        {varr.indexOf('fotoRumah') !== -1 ? (
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'fotoRumah'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                            Foto Rumah
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                    Foto Rumah
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                        <tr>
                                        {varr.indexOf('fotoTempatUsaha') !== -1 ? (
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'fotoTempatUsaha'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                            Foto Tempat Usaha
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                    Foto Tempat Usaha
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                        <tr>
                                        {varr.indexOf('suratPernyataanPenyerahanSKDariFronting') !== -1 ? (
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'suratPernyataanPenyerahanSKDariFronting'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                            Surat Pernyataan Penyerahan SK dari Fronting
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                    Surat Pernyataan Penyerahan SK dari Fronting
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                        <tr> 
                                        {varr.indexOf('fotoTandatanganPK') !== -1 ? (  
                                            documentDetails.map((documentDetails) => {
                                                if(documentDetails.type === 'fotoTandatanganPK'){
                                                    return(<>
                                                        <td>
                                                            <ListGroup.Item action variant="info" onClick={() => this.openModal(documentDetails)}>
                                                            Foto Nasabah Tanda Tangan PK
                                                            </ListGroup.Item>
                                                        </td>
                                                        {documentDetails.status === 'verify_ao' ?
                                                        <td className="align-center">
                                                            <ProgressBar className="progBar" variant="success"  label="Verified" now={100} />
                                                        </td>
                                                        :<>
                                                        {documentDetails.status === 'verify_front' ? 
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" animated label="To Be Verify by AO" now={100} />
                                                            </td>
                                                            :
                                                            <td className="align-center">
                                                                <ProgressBar className="progBar" variant="warning" label="To Be Verify" now={100} />
                                                            </td>
                                                        }
                                                        </>
                                                        }
                                                        </>)
                                                    } else {return null}
                                                })
                                            ):(
                                                <>
                                                <td>
                                                    <ListGroup.Item action variant="danger" >
                                                    Foto Nasabah Tanda Tangan PK
                                                    </ListGroup.Item>
                                                </td>
                                                <td className="align-middle">
                                                    <ProgressBar className="progBar" variant="danger"  label="Unavailable" now={100} />
                                                </td>
                                                </>
                                           )
                                        }
                                        </tr>
                                    </>
                                    )
                                 }
                                        
                                </tbody>

                            </Table>
                            </Card.Body>
                            <Card.Footer style={{ textAlign: "right" }}>
                                <ReactiveButton outline
                                    size="large"
                                    width="100px"
                                    height="50px"
                                    color={'red'}
                                    idleText="REJECT"
                                    errorText="REJECTED"
                                    buttonState={loadingReject}
                                    onClick={() => this.openModalReject(ticketId)}
                                    loadingText={
                                        <React.Fragment>
                                            <FontAwesomeIcon icon={faCircleNotch} spin /> Loading
                                        </React.Fragment>
                                        }
                                >
                                </ReactiveButton>{" "}
                                <ReactiveButton outline
                                    size="large"
                                    width="100px"
                                    height="50px"
                                    color={'green'}
                                    idleText="VERIFY"
                                    successText="VERIFIED"
                                    buttonState={loadingVerify}
                                    onClick={() => this.openModalVerify(ticketId)}
                                    loadingText={
                                        <React.Fragment>
                                            <FontAwesomeIcon icon={faCircleNotch} spin /> Loading
                                        </React.Fragment>
                                        }
                                >
                                </ReactiveButton>
                            </Card.Footer>
                        </Card>
                    </div>
                    </div>
                </div>
            </div>               
        );
    }
}

const mapStateToProps = (state) => {
    return {
        bookObject: state.document,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        
        saveDocument: (document) => dispatch(saveDocument(document)),
        fetchDocument: (documentId) => dispatch(fetchDocument(documentId)),
        verifyDocument: (documentId) => dispatch(verifyDocument(documentId)),
        rejectDocument: (documentId) => dispatch(rejectDocument(documentId)),
        verifyDocumentDetail: (documentDetail) => dispatch(verifyDocumentDetail(documentDetail)),
        assignTicket: (documentId) => dispatch(assignTicket(documentId)),
    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DocumentVerify));
