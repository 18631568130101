import * as BT from "./documentTypes";

const initialState = {
  document: "",
  byteImage: "",
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case BT.SAVE_DOCUMENT_REQUEST:
    case BT.FETCH_DOCUMENT_REQUEST:
    case BT.UPDATE_DOCUMENT_REQUEST:
    case BT.GET_DOCUMENT_IMAGE_REQUEST:
        return {
            byteImage: action.payload,
            error: "",
        };
    case BT.DELETE_DOCUMENT_REQUEST:   
      return {
        ...state,
      };
    case BT.DOCUMENT_SUCCESS:
      return {
        document: action.payload,
        error: "",
      };
    case BT.DOCUMENT_FAILURE:
      return {
        document: "",
        error: action.payload,
      };   
    default:
      return state;
  }
};

export default reducer;
